import React, {useState, useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Common/Copyright';
import {Context as AuthContext} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import logoImage from '../../assets/images/horizontalLogo.png';
import authImage from '../../assets/images/authImage.png';
import {PrimaryButton} from '../../components/Common/Buttons';
import Tile from '../../components/Common/Tile';
import GlobalConstants from '../../components/GlobalConstants';

const Login = ({showSuccess, showError}) => {
  const navigate = useNavigate();
  const loadedEmail = localStorage.getItem('email');
  const [email, setEmail] = useState(loadedEmail ? loadedEmail : '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState();

  const {
    state: {error},
    signin,
  } = useContext(AuthContext);

  const processLogin = async () => {
    setLoading(true);
    const {success, error, data} = await signin(email, password);
    // TODO: handle better
    if (success) {
      const {universal} = data;
      if (universal && universal.dashboard && universal.dashboard.active) {
        navigate('/dashboard/atRisk');
      } else if (universal && universal.pfm && universal.pfm.active) {
        // TODO: update
        navigate('/dashboard/atRisk');
      } else {
        navigate('/comingSoon');
      }

      showSuccess('Successfully signed in');
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: GlobalConstants.BACKGROUND,
        height: '100vh',
      }}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div style={{marginRight: '64px'}}>
          <CssBaseline />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <img
              alt="Finsiteful"
              className="logo-image"
              style={{width: '256px', height: '77px', marginTop: '64px'}}
              src={logoImage}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{fontWeight: 'bold', margin: '16px'}}>
              Log In to Your Dashboard
            </Typography>
            <Tile>
              <TextField
                size="small"
                value={email}
                onChange={evt => {
                  setEmail(evt.target.value);
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{minWidth: '300px'}}
              />

              <TextField
                size="small"
                value={password}
                onChange={evt => {
                  setPassword(evt.target.value);
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              {error && (
                <Typography sx={{color: GlobalConstants.RED}}>
                  {error}
                </Typography>
              )}

              <PrimaryButton
                text="Log in"
                onClick={processLogin}
                detailed
                icon
              />
              <Link
                href="/forgotPassword"
                variant="body2"
                color={GlobalConstants.HIGHLIGHT}>
                Forgot password?
              </Link>
            </Tile>
            {/* <Typography sx={{margin: '16px', fontWeight: '500'}}>
              New to FinSiteful?{' '}
              <Link
                href="/signup"
                variant="body2"
                color={GlobalConstants.HIGHLIGHT}>
                Sign Up
              </Link>
            </Typography> */}
          </Box>
        </div>
        <div style={{}}>
          <img
            alt="Finsiteful"
            className="logo-image"
            style={{width: '256px', height: '218px', marginTop: '64px'}}
            src={authImage}
          />
        </div>
      </div>
      <Copyright sx={{mt: 8, mb: 4}} />
    </div>
  );
};

export default Login;
