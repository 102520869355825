import {
  Badge,
  Card,
  CardHeader,
  Container,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  Pagination,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {prettifyShortDate, prettifyTime} from '../../api/dateManager';
import {trackEvent} from '../../api/segment';
import Copyright from '../../components/Common/Copyright';
import Paginator from '../../components/Common/Paginator';
import Spacer from '../../components/Common/Spacer';
import GlobalConstants from '../../components/GlobalConstants';
import {Context as DashboardContext} from '../../contexts/DashboardContext';
import {useAuth} from '../../hooks/useAuth';

const MessagesHub = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="lg">
          <Box sx={{mb: 4}}>
            <Typography variant="h4">Messenger</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <MessageChain />
            </Grid>
          </Grid>
        </Container>
        <Copyright sx={{pt: 4}} />
      </Box>
    </>
  );
};

const MessageChain = () => {
  const navigate = useNavigate();
  const {
    state: {universal},
  } = useAuth();

  const {
    state: {messageHub, messageMetadata},
    getMessagesMetadata,
    setMessageMetadata,
  } = useContext(DashboardContext);

  const [page, setPage] = useState(1);
  const pageLength = 10;
  const start = (page - 1) * pageLength;
  const end = page * pageLength;
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (!messageHub.length && !loading) {
      setLoading(true);
      const {organizationCode} = universal;
      await getMessagesMetadata(organizationCode);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card>
      <List>
        {messageHub.slice(start, end).map(message => {
          const data = messageMetadata[message];
          const {uid, admin, student, lastMessage, lastUpdated} = data || {};
          const update = lastUpdated ? new Date(lastUpdated) : null;
          const seen = admin ? new Date(admin.lastSeen) : null;
          return (
            <Badge
              badgeContent={update && seen && seen >= update ? 0 : '!'}
              color={'error'}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <ListItemButton
                sx={{
                  display: 'flex',
                }}
                onClick={() => {
                  if (!update || !seen || seen < update) {
                    let now = new Date().getTime();
                    const update = {
                      ...data,
                      admin: {lastSeen: now},
                    };
                    setMessageMetadata(uid, update);
                  }
                  navigate('/messenger/user', {state: {metadata: data}});
                  trackEvent('messageChainViewed', {
                    messageMetadata: data,
                  });
                }}>
                <div style={{}}>
                  <Typography sx={{fontSize: 14}}>
                    {prettifyShortDate(update)}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: 14,
                    }}>
                    {prettifyTime(update)}
                  </Typography>
                </div>
                <Spacer />
                <div style={{flex: 1}}>
                  <Typography
                    sx={{
                      flex: 1,
                      color: GlobalConstants.HIGHLIGHT,
                      fontWeight: 'bold',
                    }}>
                    {student.firstName} {student.lastName}
                  </Typography>
                </div>
                <div style={{flex: 1}}>
                  <Typography>
                    {/* {lastMessage.body.slice(0, 100)} */}
                    {lastMessage.body}
                  </Typography>
                </div>
              </ListItemButton>
              <Divider sx={{width: '100%'}} />
            </Badge>
          );
        })}
      </List>
      <Paginator
        length={messageHub.length}
        pageLength={pageLength}
        page={page}
        setPage={setPage}
      />
    </Card>
  );
};

export default MessagesHub;
