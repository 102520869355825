import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  PieChart,
  Pie,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  Cell,
  ComposedChart,
  Area,
} from 'recharts';
import {Typography} from '@mui/material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'purple', '#FF5722', 'orange'];

export const CustomBarChart = ({data, legend, children}) => {
  return (
    <ResponsiveContainer width={'95%'} height={320}>
      <BarChart data={data} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" hide />
        <Tooltip />
        {legend && <Legend />}
        {children ? children : <Bar dataKey="value" fill="#8884d8" />}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CustomPieChart = ({data, slice, height}) => {
  return (
    <div style={{height: height, marginBottom: '47px'}}>
      <ResponsiveContainer
        width="100%"
        height="100%"
        style={{alignSelf: 'center'}}>
        <PieChart style={{alignSelf: 'center', margin: '50px 0 16px'}}>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="top"
            width="45%"
            margin={{left: 16}}
          />
          <Pie
            data={data.slice(0, 6)}
            activeIndex={1}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={70}
            labelLine={true}
            legendType="line"
            label>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export const CustomLineChart = ({data, children, legend}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width={'95%'} height={300} sx={{mt: 4, mb: 4}}>
      <LineChart
        data={data}
        margin={{
          top: 16,
          right: 16,
          bottom: 0,
          left: 24,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
        />
        <YAxis
          stroke={theme.palette.text.secondary}
          style={theme.typography.body2}
        />
        {children ? (
          children
        ) : (
          <Line type="monotone" dataKey="value" dot={false} />
        )}
        {legend && <Legend align="right" verticalAlign="bottom" />}
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomComposedChart = ({data, children, legend}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" />
        <Tooltip />
        <Legend />
        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
        <Bar dataKey="pv" barSize={20} fill="#413ea0" />
        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
