import React, {useState, useContext, useEffect} from 'react';
import {
  Box,
  Toolbar,
  Typography,
  Grid,
  Container,
  Paper,
  Drawer,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@mui/material';
import Copyright from '../../components/Common/Copyright';
import {AtRiskInNeed, AtRiskInProgress} from './StudentList';
import BarChartTile from '../../components/Charts/BarChartTile';
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from '../../components/Common/Buttons';
import Paginator from '../../components/Common/Paginator';
import {trackEvent} from '../../api/segment';
import {useDashboard} from '../../hooks/useDashboard';
import {useAuth} from '../../hooks/useAuth';
import {SideDrawer} from '../../components/Layout/side-drawer';
import {SimpleBox} from '../../components/Layout/cards';

const objectToSortedArray = obj => {
  const array = Object.keys(obj)
    .map(key => {
      return {name: key, value: obj[key]};
    })
    .sort((a, b) => a.value < b.value);
  return array;
};

const toReadable = key => {
  switch (key) {
    case 'total':
      return 'Total';
    case 'cashFlow':
      return 'Negative Cash Flow';
    case 'netWorth':
      return 'Negative Net Worth';
    case 'overdraft':
      return 'Overdraft';
    case 'creditDebt':
      return 'Credit Card Debt';
    case 'creditUtilization':
      return 'High Credit Utilization';
    case 'interest':
      return 'Interest';
    default:
      return key;
  }
};

const AtRiskDashboard = ({}) => {
  const {
    state: {universal},
  } = useAuth();
  const {state} = useDashboard();

  const [sidebar, setSidebar] = useState(false);

  const [user, setUser] = useState(null);
  const [resource, setResource] = useState([]);

  const reset = () => {
    setSidebar(false);
    setUser(null);
    setResource([]);
  };

  const {
    users,
    linked,
    atRisk,
    atRiskBreakdown,
    atRiskByDay,
    atRiskTransactionCategoriesPerMonth,
    atRiskTransactionTypesPerMonth,
    atRiskUsers,
    atRiskPillarBreakdown,
    averageAtRiskNetWorth,
  } = state;

  delete atRiskBreakdown.possible;
  delete atRiskBreakdown.new;
  const atRiskArray = objectToSortedArray(atRiskBreakdown);
  const array = Object.keys(atRiskBreakdown)
    .map(key => {
      return {name: toReadable(key), value: atRiskBreakdown[key]};
    })
    .sort((a, b) => a.value < b.value);

  const helped = atRiskUsers.filter(user => user.atRiskResolved);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="lg">
          <Box sx={{mb: 4}}>
            <Typography variant="h4">At-Risk Dashboard</Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <AtRiskInNeed
                data={atRiskUsers}
                count={atRisk}
                setSidebar={setSidebar}
                setUser={setUser}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AtRiskInProgress
                data={atRiskUsers}
                count={atRisk}
                setSidebar={setSidebar}
                setUser={setUser}
              />
            </Grid>
            {/* CURRENT AT RISK */}
            <Grid item xs={12} md={12} lg={12}>
              <BarChartTile
                header="At-Risk Causes"
                subheader="Below are the top factors that cause your students to be at-risk."
                info="A bar graph displaying financial events that cause your students to be at-risk."
                data={array}
              />
            </Grid>

            <SimpleBox
              header="Students Helped"
              info="See how many at-risk students you’ve helped this year!"
              data={helped.length}>
              <Button
                sx={{alignSelf: 'center', margin: '16px'}}
                onClick={() => {
                  setSidebar(true);
                  trackEvent('studentsHelpedViewed');
                }}>
                SEE ALL
              </Button>
            </SimpleBox>
          </Grid>
        </Container>
        <SideDrawer
          header={'Students'}
          open={sidebar}
          onClose={reset}
          onOpen={() => setSidebar(true)}>
          <StudentsHelped students={helped} setUser={setUser} />
        </SideDrawer>
        <Copyright sx={{pt: 4}} />
      </Box>
    </>
  );
};

const StudentsHelped = ({students, setUser}) => {
  const [page, setPage] = useState(1);
  const pageLength = 10;
  const start = (page - 1) * pageLength;
  const end = page * pageLength;

  return (
    <Card>
      <CardHeader title="Students Helped" />
      <Divider />
      <Typography sx={{fontSize: 24, m: 2}} textAlign="center">
        {students && students.length ? students.length : 0}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{}}>Start Date</TableCell>
            <TableCell sx={{}}>Name</TableCell>
            <TableCell sx={{}}>Email</TableCell>
            <TableCell sx={{}}>Student ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.slice(start, end).map(row => {
            const {atRiskDate, firstName, lastName, email, studentID} = row;
            const access = row.shareAtRiskAccepted ? true : false;

            return (
              <TableRow key={row.uid} sx={{}}>
                <TableCell>{atRiskDate}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setUser(row);
                    }}>
                    {firstName && lastName
                      ? firstName + ' ' + lastName
                      : 'John Doe'}
                  </Button>
                </TableCell>
                <TableCell>{email || 'jdoe@gmail.com'}</TableCell>
                <TableCell>{studentID || 'N/A'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Paginator
        length={students.length}
        pageLength={pageLength}
        page={page}
        setPage={setPage}
      />
    </Card>
  );
};

export default AtRiskDashboard;
