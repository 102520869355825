import {Typography, Link} from '@mui/material';

const Copyright = props => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{margin: 20}}
      {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://finsiteful.com/">
        FinSiteful.Inc
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
