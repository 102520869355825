import {Card, Divider, Grid, Tooltip, Typography} from '@mui/material';
import {InfoCircleIcon} from '../../assets/icons/info-circle';
import GlobalConstants from '../GlobalConstants';

export const SimpleBox = ({header, info, data, children}) => {
  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{backgroundColor: GlobalConstants.HEADER}}>
          <Typography color="textSecondary" variant="body2" sx={{px: 3, py: 2}}>
            {header}
          </Typography>
          {info && (
            <Tooltip title={info} sx={{mr: 2}}>
              <InfoCircleIcon />
            </Tooltip>
          )}
        </Grid>
        <Divider />
        <Typography
          sx={{mt: 1, textAlign: 'center', px: 3, py: 2}}
          variant="h5">
          {data}
        </Typography>
        {children}
      </Card>
    </Grid>
  );
};
