import {useEffect, useState} from 'react';

const useVisible = () => {
  const useOnScreen = ref => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    useEffect(() => {
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);

    return isIntersecting;
  };

  return [useOnScreen];
};

export default useVisible;
