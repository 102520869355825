import {useEffect, useState} from 'react';
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {AccountGeneralSettings} from './GeneralSettings';
import {AccountTeamSettings} from './TeamSettings';
import {AccountNotificationsSettings} from './NotificationSettings';
import {useLocation} from 'react-router-dom';
import {useDashboard} from '../../hooks/useDashboard';
import {useAuth} from '../../hooks/useAuth';
import Copyright from '../../components/Common/Copyright';

const tabs = [
  {label: 'General', value: 'general'},
  {label: 'Team', value: 'team'},
  {label: 'Notifications', value: 'notifications'},
];

const Account = () => {
  const location = useLocation();

  const {
    state: {uid, universal},
  } = useAuth();
  const {
    state: {notificationSettings, team},
    fetchTeam,
    updateSettings,
  } = useDashboard();

  const [currentTab, setCurrentTab] = useState('general');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const {organizationCode} = universal;
      await fetchTeam(organizationCode);
      setLoading(false);
    };
    fetch();
  }, []);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="md">
          <Typography variant="h4">Account</Typography>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            sx={{mt: 3}}>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider sx={{mb: 3}} />
          {currentTab === 'general' && <AccountGeneralSettings />}
          {currentTab === 'team' && <AccountTeamSettings />}
          {currentTab === 'notifications' && <AccountNotificationsSettings />}
        </Container>
        <Copyright sx={{pt: 4}} />
      </Box>
    </>
  );
};

export default Account;
