import createDataContext from './createDataContext';
import {doc} from 'firebase/firestore';
import {
  getAtRiskData,
  getDashboardData,
  sendMessage,
  updateDashboardProfile,
  updateNotificationSettings,
  getMessages,
  getMessageChainListener,
  updateMessageMetadata,
  getMessage,
  getTeamSettings,
  updateTeamSettings,
  getNotes,
  setNote,
  deleteNote,
} from '../api/firestore';
import {
  convertToDate,
  currentLocalTimeToString,
  dateToString,
  goBackInTimeFromToday,
} from '../api/dateManager';
import {trackEvent} from '../api/segment';

const dashboardReducer = (state, action) => {
  const {uid, message, update, messageMetadata, messageHub, notes, note} =
    action.payload;
  switch (action.type) {
    case 'fetchTeam':
      return {...state, ...action.payload};
    case 'updateTeam':
      return {...state, team: {...state.team, ...action.payload.update}};
    case 'updateSettings':
      return {...state, notificationSettings: action.payload.settings};
    case 'getData':
      return {...state, ...action.payload};
    case 'fakeData':
      return {...state, ...action.payload};
    case 'getMessages':
      const hub = [...state.messageHub, ...messageHub];
      const metadata = {...state.messageMetadata, ...messageMetadata};
      return {...state, messageHub: hub, messageMetadata: metadata};
    case 'getMetadata':
      const updatedMetadata = {
        ...state.messageMetadata,
        [uid]: messageMetadata,
      };
      return {...state, messageMetadata: updatedMetadata};
    case 'setMetadata':
      const newMeta = {
        ...state.messageMetadata,
        [uid]: {...state.messageMetadata[uid], ...update},
      };
      return {...state, messageMetadata: newMeta};
    case 'getMessageChain':
      const updatedMessages = {
        ...state.messageChain,
        [action.payload.uid]: action.payload.messages,
      };
      return {...state, messageChain: updatedMessages};
    case 'sendMessage':
      const updateMessages = {
        ...state.messageChain,
        [uid]: [...state.messages[uid], message],
      };
      return {...state, messageChain: updateMessages};
    case 'updateDashboardProfile':
      const filtered = state.users.map(user => {
        if (user.uid === uid) {
          return {...user, ...update};
        }
        return user;
      });
      const filteredRisk = state.atRiskUsers.map(user => {
        if (user.uid === uid) {
          return {...user, ...update};
        }
        return user;
      });
      return {...state, users: filtered, atRiskUsers: filteredRisk};
    case 'getNotes':
      return {...state, notes: {...state.notes, [uid]: notes}};
    case 'addNote':
      const current = state.notes[uid] || [];
      const addedNotes = [update, ...current];
      return {...state, notes: {...state.notes, [uid]: addedNotes}};
    case 'deleteNote':
      const currentDelete = state.notes[uid] || [];
      const deleted = currentDelete.filter(current => current.uid !== note.uid);
      return {...state, notes: {...state.notes, [uid]: deleted}};
    case 'updateNote':
      const currentNotes = state.notes[uid] || [];
      const updatedNotes = currentNotes.map(note => {
        if (note.uid === update.uid) {
          return {...note, ...update};
        }
        return note;
      });
      const orderedNotes = updatedNotes.sort((a, b) => b.updated - a.updated);
      return {...state, notes: {...state.notes, [uid]: orderedNotes}};
    default:
      return state;
  }
};

const fetchTeam = dispatch => async code => {
  try {
    const team = await getTeamSettings(code);
    const payload = {team};
    dispatch({type: 'fetchTeam', payload});
  } catch {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const updateTeam = dispatch => async (code, update) => {
  try {
    const team = await updateTeamSettings(code, update);
    const payload = {update};
    dispatch({type: 'updateTeam', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const updateSettings = dispatch => async (uid, settings) => {
  try {
    await updateNotificationSettings(uid, settings);
    const payload = {settings};
    dispatch({type: 'updateSettings', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const getProfiles = dispatch => async filter => {
  try {
    const today = currentLocalTimeToString();
    const payload = {
      lastRefresh: null,
      riskCount: 0,
      askHelp: 0,
      budgetCreated: 0,
      savingsCreated: 0,
      debtCreated: 0,
      linked: 0,
      atRisk: 0,
      activeLinked: 0,
      notLinked: 0,
      notOnboarded: 0,
      raffleWinnings: 0,
      netWorth: 0,
      atRiskNetWorth: 0,
      usersWithAccounts: 0,
      deleted: 0,
    };
    // GET DATA
    const users = await getDashboardData(filter);
    if (users.length > 0) {
      const firstUser = users[0];
      payload.lastRefresh = firstUser.lastRefreshed;
    }
    // PROCESS DATA
    const signUpOverTime = {};
    const signUpsSummation = {};

    const employmentSummary = {};
    const genderSummary = {};
    const majorSummary = {};
    const birthdaySummary = {};
    const moduleInteractionSummary = {};
    const birthDecade = {
      1960: 0,
      1970: 0,
      1980: 0,
      1990: 0,
      2000: 0,
      2010: 0,
    };

    const appProgressSummary = {
      notOnboarded: 0,
      incomplete: 0,
      completeActive: 0,
      completeInactive: 0,
    };
    const linkedAccountsSum = {
      checking: 0,
      credit: 0,
      debt: 0,
      investment: 0,
      savings: 0,
      other: 0,
      undefined: 0,
    };
    const pillarBreakdown = {
      checking: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Checking/Budget',
      },
      credit: {
        total: 0,
        limit: 0,
        utilization: 0,
        average: 0,
        count: 0,
        users: 0,
        name: 'Credit',
      },
      debt: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Debt',
      },
      investment: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        name: 'Investments',
      },
      savings: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Savings',
      },
      other: {total: 0, average: 0, count: 0, users: 0, name: 'Other'},
      undefined: {total: 0, average: 0, count: 0, users: 0, goalCreated: 0},
    };
    const atRiskPillarBreakdown = {
      checking: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Checking/Budget',
      },
      credit: {
        total: 0,
        limit: 0,
        utilization: 0,
        average: 0,
        count: 0,
        users: 0,
        name: 'Credit',
      },
      debt: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Debt',
      },
      investment: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        name: 'Investments',
      },
      savings: {
        total: 0,
        average: 0,
        count: 0,
        users: 0,
        goalCreated: 0,
        name: 'Savings',
      },
      other: {total: 0, average: 0, count: 0, name: 'Other'},
      undefined: {total: 0, average: 0, count: 0, goalCreated: 0},
    };
    const atRiskSummary = {
      possible: 0,
      total: 0,
      netWorth: 0,
      creditUtilization: 0,
      creditDebt: 0,
      cashFlow: 0,
      overdraft: 0,
      interest: 0,
      new: 0,
    };
    const transactionBreakdown = {};
    const atRiskTransactionBreakdown = {};
    const atRiskProfiles = [];
    const profiles = users.map(user => {
      if (filterFinsiteful(user.uid)) {
        return;
      }
      if (user.deleted) {
        payload.deleted += 1;
      }

      // SET UP STATUS
      // ONBOARDED
      if (user.isOnboarded) {
        // LINKED
        if (user.linked) {
          payload.linked += 1;
          if (user.plaidItems.length && user.accounts.length) {
            payload.activeLinked += 1;
            appProgressSummary.completeActive += 1;
          } else {
            appProgressSummary.completeInactive += 1;
            payload.notLinked += 1;
          }
        } else {
          appProgressSummary.incomplete += 1;
          payload.notLinked += 1;
        }
      } else {
        appProgressSummary.notOnboarded += 1;
        payload.notOnboarded += 1;
      }
      // MONEY WON
      if (user.moneyWon) {
        payload.raffleWinnings += user.moneyWon;
      }
      // CREATED PILLARS
      if (user.budgetCreated) {
        payload.budgetCreated += 1;
      }
      if (user.savingsCreated) {
        payload.savingsCreated += 1;
      }
      if (user.debtCreated) {
        payload.debtCreated += 1;
      }
      // SHARE DATA/ASK FOR HELP

      if (user.atRisk) {
        payload.atRisk += 1;
      }
      if (user.shareAtRiskAccepted) {
        payload.askHelp += 1;
      }
      // SIGN UPS/DATE CREATED
      if (user.dateCreated) {
        const date = convertToDate(user.dateCreated);
        const dateString = date.toISOString().split('T')[0];
        if (signUpOverTime[dateString] === undefined) {
          signUpOverTime[dateString] = 1;
        } else {
          signUpOverTime[dateString] += 1;
        }
      }
      if (employmentSummary[user.employmentStatus] === undefined) {
        employmentSummary[user.employmentStatus] = 1;
      } else {
        employmentSummary[user.employmentStatus] += 1;
      }
      // GENDER
      if (genderSummary[user.gender] === undefined) {
        genderSummary[user.gender] = 1;
      } else {
        genderSummary[user.gender] += 1;
      }
      // MAJOR
      if (majorSummary[user.major] === undefined) {
        majorSummary[user.major] = 1;
      } else {
        majorSummary[user.major] += 1;
      }
      // BIRTHDAY
      // if (user.birthday) {
      //   const birthday = convertToDate(user.birthday);
      //   const birthYear = birthday.getFullYear();
      //   // BIRTH YEAR
      //   if (birthdaySummary[birthYear] === undefined) {
      //     birthdaySummary[birthYear] = 1;
      //   } else {
      //     birthdaySummary[birthYear] += 1;
      //   }
      // }

      if (user.birthday) {
        const birthday = convertToDate(user.birthday);
        const birthYear = birthday.getFullYear();
        if (birthYear >= '1960' && birthYear < '1970') {
          birthDecade['1960'] += 1;
        }
        if (birthYear >= '1970' && birthYear < '1980') {
          birthDecade['1970'] += 1;
        }
        if (birthYear >= '1980' && birthYear < '1990') {
          birthDecade['1980'] += 1;
        }
        if (birthYear >= '1990' && birthYear < '2000') {
          birthDecade['1990'] += 1;
        }
        if (birthYear >= '2000' && birthYear < '2010') {
          birthDecade['2000'] += 1;
        }
        if (birthYear >= '2010' && birthYear < '2020') {
          birthDecade['2010'] += 1;
        }
      }

      // ACCOUNTS
      const accountsLinked = {
        checking: false,
        savings: false,
        credit: false,
        investment: false,
        debt: false,
        other: false,
      };
      const atRiskAccountsLinked = {
        checking: false,
        savings: false,
        credit: false,
        investment: false,
        debt: false,
        other: false,
      };
      // GOALS CREATED
      if (user.linked && user.plaidItems.length && user.accounts.length) {
        if (user.budgetCreated) {
          pillarBreakdown.checking.goalCreated += 1;
        }
        if (user.savingsCreated) {
          pillarBreakdown.savings.goalCreated += 1;
        }
        if (user.debtCreated) {
          pillarBreakdown.debt.goalCreated += 1;
        }
      }
      // AT RISK GOALS CREATED
      if (user.atRiskData && user.atRiskData.atRisk) {
        if (user.budgetCreated) {
          atRiskPillarBreakdown.checking.goalCreated += 1;
        }
        if (user.savingsCreated) {
          atRiskPillarBreakdown.savings.goalCreated += 1;
        }
        if (user.debtCreated) {
          atRiskPillarBreakdown.debt.goalCreated += 1;
        }
      }
      if (user.accounts.length && user.plaidItems.length) {
        payload.usersWithAccounts += 1;
        user.accounts.forEach(account => {
          // TODO: shouldnt be an issue anymore
          if (!account.accountType) {
            return;
          }
          linkedAccountsSum[account.accountType] += 1;
          const balance = Number(
            account.accountType === 'credit' || account.accountType === 'debt'
              ? -1 * account.balances.current
              : account.balances.available
              ? account.balances.available
              : account.balances.current,
          );
          if (account.accountType === 'credit') {
            // Sometimes limit can be null
            pillarBreakdown['credit'].limit += account.balances.limit
              ? account.balances.limit
              : 0;
          }
          accountsLinked[account.accountType] = true;
          pillarBreakdown[account.accountType].count += 1;
          pillarBreakdown[account.accountType].total += Math.abs(balance);

          // IF AT RISK
          if (user.atRiskData && user.atRiskData.atRisk) {
            atRiskAccountsLinked[account.accountType] = true;
            if (account.accountType === 'credit') {
              // Sometimes limit can be null
              atRiskPillarBreakdown['credit'].limit += account.balances.limit
                ? account.balances.limit
                : 0;
            }
            // ACCOUNT COUNT
            atRiskPillarBreakdown[account.accountType].count += 1;
            atRiskPillarBreakdown[account.accountType].total +=
              Math.abs(balance);
            payload.atRiskNetWorth += balance;
          }
          payload.netWorth += balance;
        });
        // COUNTING USERS
        Object.keys(accountsLinked).forEach(key => {
          const linked = accountsLinked[key];
          if (linked) {
            pillarBreakdown[key].users += 1;
          }
        });
        Object.keys(atRiskAccountsLinked).forEach(key => {
          const linked = atRiskAccountsLinked[key];
          if (linked) {
            atRiskPillarBreakdown[key].users += 1;
          }
        });
      }

      // AT RISK
      if (user.atRiskData) {
        if (user.atRiskData.atRisk) {
          payload.riskCount += 1;
          atRiskProfiles.push(user);
        }
        atRiskSummary.possible += 1;

        let needsHelp = false;
        Object.keys(user.atRiskData).forEach(type => {
          const risk = user.atRiskData[type].risk;
          if (risk) {
            needsHelp = true;
            atRiskSummary[type] += 1;
          }
        });
        if (needsHelp) {
          atRiskSummary.total += 1;
        }
      }

      // MODULES
      Object.keys(user.moduleData).forEach(module => {
        const data = user.moduleData[module];
        let interacted = false;
        Object.keys(data).forEach(key => {
          const field = data[key];
          if (field === true && !interacted) {
            interacted = true;
          }
        });
        if (interacted) {
          if (moduleInteractionSummary[module] === undefined) {
            moduleInteractionSummary[module] = 1;
          } else {
            moduleInteractionSummary[module] += 1;
          }
        }
      });

      // TRANSACTIONS
      // function that sorts transactions by category, type, and merchant
      const sortTransactions = (transactionBreakdown, user, month) => {
        if (transactionBreakdown[month] === undefined) {
          transactionBreakdown[month] = {
            count: 0,
            types: {
              spending: {count: 0, amount: 0},
              savings: {count: 0, amount: 0},
              investment: {count: 0, amount: 0},
              income: {count: 0, amount: 0},
              bill: {count: 0, amount: 0},
              ignore: {count: 0, amount: 0},
              transfer: {count: 0, amount: 0},
              creditCardPayoff: {count: 0, amount: 0},
              creditCardReceived: {count: 0, amount: 0},
            },
            categories: {},
            merchants: {},
            savings: {total: 0, max: null, min: null},
            users: 0,
          };
        }
        const userSavings = user.transactionBreakdown[month].savings;
        transactionBreakdown[month].savings.total += userSavings;
        const max = transactionBreakdown[month].savings.max;
        const min = transactionBreakdown[month].savings.min;
        if (max === null || userSavings > max) {
          transactionBreakdown[month].savings.max = userSavings;
        }
        if (min === null || userSavings < min) {
          transactionBreakdown[month].savings.min = userSavings;
        }
        const transactionCount = user.transactionBreakdown[month].count;
        if (transactionCount) {
          transactionBreakdown[month].count += transactionCount;
          transactionBreakdown[month].users += 1;
        }
        // transactionBreakdown[month].users += 1;
        // TYPES
        Object.keys(user.transactionBreakdown[month].types).forEach(key => {
          const data = user.transactionBreakdown[month].types[key];
          transactionBreakdown[month].types[key].amount += data.amount;
          transactionBreakdown[month].types[key].count += data.count;
        });
        // CATEGORIES
        Object.keys(user.transactionBreakdown[month].categories).forEach(
          key => {
            const data = user.transactionBreakdown[month].categories[key];
            if (transactionBreakdown[month].categories[key] === undefined) {
              transactionBreakdown[month].categories[key] = {
                count: 0,
                amount: 0,
              };
            }
            transactionBreakdown[month].categories[key].amount += data.amount;
            transactionBreakdown[month].categories[key].count += data.count;
          },
        );
        // MERCHANTS
        Object.keys(user.transactionBreakdown[month].merchants).forEach(key => {
          const data = user.transactionBreakdown[month].merchants[key];
          if (transactionBreakdown[month].merchants[key] === undefined) {
            transactionBreakdown[month].merchants[key] = {
              count: 0,
              amount: 0,
            };
          }
          transactionBreakdown[month].merchants[key].amount += data.amount;
          transactionBreakdown[month].merchants[key].count += data.count;
        });
      };
      if (user.transactionBreakdown) {
        Object.keys(user.transactionBreakdown).forEach(month => {
          sortTransactions(transactionBreakdown, user, month);
          if (user.atRiskData && user.atRiskData.atRisk) {
            sortTransactions(atRiskTransactionBreakdown, user, month);
          }
        });
      }

      // RETURN THE DATA
      return user;
    });

    // FIND AVERAGE BALANCE
    Object.keys(pillarBreakdown).forEach(key => {
      pillarBreakdown[key].average = pillarBreakdown[key].users
        ? pillarBreakdown[key].total / pillarBreakdown[key].users
        : 0;
      if (key === 'credit') {
        pillarBreakdown[key].utilization = pillarBreakdown[key].limit
          ? (pillarBreakdown[key].total * 100) / pillarBreakdown[key].limit
          : 0;
      }
    });
    Object.keys(atRiskPillarBreakdown).forEach(key => {
      atRiskPillarBreakdown[key].average = atRiskPillarBreakdown[key].users
        ? atRiskPillarBreakdown[key].total / atRiskPillarBreakdown[key].users
        : 0;
      if (key === 'credit') {
        atRiskPillarBreakdown[key].utilization = atRiskPillarBreakdown[key]
          .limit
          ? (atRiskPillarBreakdown[key].total * 100) /
            atRiskPillarBreakdown[key].limit
          : 0;
      }
    });

    // REMOVE UNDEFINED
    delete employmentSummary.null;
    delete genderSummary.null;
    delete majorSummary.null;
    delete linkedAccountsSum.undefined;
    // TURN INTO ARRAYS FOR GRAPH
    const employment = objectToSortedArray(employmentSummary);
    const gender = objectToSortedArray(genderSummary);
    const major = objectToSortedArray(majorSummary);
    const birthYears = objectToSortedArray(birthDecade);
    const signUps = objectToArray(signUpOverTime).sort(
      (a, b) => a.name > b.name,
    );
    const accountTypes = objectToSortedArray(linkedAccountsSum);
    // const atRiskBreakdown = objectToSortedArray(atRiskSummary);

    let total = 0;
    let start = signUps.length ? signUps[0].name : null;
    let days = getDatesInRange(start, today);
    const signUpsSum = days.map(day => {
      const signUps = signUpOverTime[day];
      if (signUps) {
        total += signUps;
      }
      return {date: day, value: total};
    });

    const savings = Object.keys(transactionBreakdown).map(month => {
      const data = transactionBreakdown[month];
      return {
        date: month,
        totalSavings: data.savings.total,
        users: data.users,
        averageSavings: data.users ? data.savings.total / data.users : 0,
        maxSavings: data.savings.max,
        minSavings: data.savings.min,
      };
    });

    const categories = Object.keys(transactionBreakdown)
      .map(month => {
        const data = transactionBreakdown[month];
        const temp = {date: month, count: data.count, users: data.users};
        Object.keys(data.categories).forEach(key => {
          const values = data.categories[key];
          const averagePerTransaction = values.amount / values.count;
          const averagePerUser = values.amount / data.users;
          temp[key] = averagePerUser;
        });
        return temp;
      })
      .sort((a, b) => a.date > b.date);

    const coreSpending = categories.map(month => {
      let transportation = 0;
      let food = 0;
      let livingExpenses = 0;
      Object.keys(month).forEach(key => {
        const data = month[key];
        if (key === 'Auto and Commuting') {
          transportation += data;
        } else if (key === 'Food and Drink' || key === 'Groceries') {
          food += data;
        } else if (
          key === 'Healthcare' ||
          key === 'Home Improvement' ||
          key === 'Utilities'
        ) {
          livingExpenses += data;
        }
      });
      return {
        date: month.date,
        Transportation: transportation.toFixed(2),
        Food: food.toFixed(2),
        ['Living Expenses']: livingExpenses.toFixed(2),
      };
    });

    const types = Object.keys(transactionBreakdown)
      .map(month => {
        const data = transactionBreakdown[month];
        const temp = {date: month, count: data.count, users: data.users};
        Object.keys(data.types).forEach(key => {
          const values = data.types[key];
          const averagePerTransaction = values.amount / values.count;
          const averagePerUser = values.amount / data.users;
          temp[key] = averagePerUser.toFixed(2);
        });
        return temp;
      })
      .sort((a, b) => a.date > b.date);

    const atRiskCategories = Object.keys(atRiskTransactionBreakdown)
      .map(month => {
        const data = atRiskTransactionBreakdown[month];
        const temp = {date: month, count: data.count, users: data.users};
        Object.keys(data.categories).forEach(key => {
          const values = data.categories[key];
          const averagePerTransaction = values.amount / values.count;
          const averagePerUser = values.amount / data.users;
          temp[key] = averagePerUser;
        });
        return temp;
      })
      .sort((a, b) => a.date > b.date);

    const atRiskCoreSpending = atRiskCategories.map(month => {
      let transportation = 0;
      let food = 0;
      let livingExpenses = 0;
      Object.keys(month).forEach(key => {
        const data = month[key];
        if (key === 'Auto and Commuting') {
          transportation += data;
        } else if (key === 'Food and Drink' || key === 'Groceries') {
          food += data;
        } else if (
          key === 'Healthcare' ||
          key === 'Home Improvement' ||
          key === 'Utilities'
        ) {
          livingExpenses += data;
        }
      });
      return {
        date: month.date,
        Transportation: transportation.toFixed(2),
        Food: food.toFixed(2),
        ['Living Expenses']: livingExpenses.toFixed(2),
      };
    });

    const atRiskTypes = Object.keys(atRiskTransactionBreakdown)
      .map(month => {
        const data = atRiskTransactionBreakdown[month];
        const temp = {date: month, count: data.count, users: data.users};
        Object.keys(data.types).forEach(key => {
          const values = data.types[key];
          const averagePerTransaction = values.amount / values.count;
          const averagePerUser = values.amount / data.users;
          temp[key] = averagePerUser.toFixed(2);
        });
        return temp;
      })
      .sort((a, b) => a.date > b.date);
    payload.users = profiles;
    payload.atRiskUsers = atRiskProfiles;

    payload.averageNetWorth = payload.netWorth / payload.usersWithAccounts;
    payload.averageAtRiskNetWorth = payload.atRiskNetWorth / payload.riskCount;

    payload.moduleInteraction = moduleInteractionSummary;
    payload.employmentBreakdown = employment;
    payload.genderBreakdown = gender;
    payload.majorBreakdown = major;
    payload.birthYearBreakdown = birthYears;
    payload.signUpsByDay = signUps;
    payload.signUpsSummation = signUpsSum;
    payload.accountTypeSum = accountTypes;
    payload.atRiskBreakdown = atRiskSummary;
    payload.pillarBreakdown = pillarBreakdown;
    payload.atRiskPillarBreakdown = atRiskPillarBreakdown;
    payload.transactionCategoriesPerMonth = coreSpending;
    payload.transactionTypesPerMonth = types;
    payload.atRiskTransactionCategoriesPerMonth = atRiskCoreSpending;
    payload.atRiskTransactionTypesPerMonth = atRiskTypes;
    payload.potentialSavings = savings;
    payload.appProgress = appProgressSummary;

    dispatch({type: 'getData', payload});
  } catch (err) {
    console.error(err);
    dispatch({type: 'error', payload: 'Something went wrong.'});
  }
};

// TODO: filter?
const getAtRisk = dispatch => async filter => {
  try {
    const sortByDay = {};
    const twoWeeksString = dateToString(goBackInTimeFromToday(0, 0, 14));
    const atRisk = await getAtRiskData(twoWeeksString, filter);
    atRisk.forEach(user => {
      // FILTER OUT STUDENTS
      if (filter && user.organizationCode !== filter) {
        return;
      }
      user.date = doc.id;
      if (sortByDay[user.date] === undefined) {
        sortByDay[user.date] = [user];
      } else {
        sortByDay[user.date].push(user);
      }
    });
    const atRiskByDay = Object.keys(sortByDay).map(key => {
      return {...getRiskSummary(sortByDay[key]), date: key};
    });
    const payload = {atRiskByDay};
    dispatch({type: 'getData', payload});
  } catch (err) {
    console.error(err);
    dispatch({type: 'error', payload: 'Something went wrong.'});
  }
};

// TODO: app opens

const getRiskSummary = array => {
  const summary = {
    possible: array.length,
    total: 0,
    netWorth: 0,
    creditUtilization: 0,
    creditDebt: 0,
    cashFlow: 0,
    overdraft: 0,
    interest: 0,
    new: 0,
  };
  array.forEach(user => {
    let needsHelp = false;
    Object.keys(user).forEach(type => {
      const risk = user[type].risk;
      if (risk) {
        needsHelp = true;
        summary[type] += 1;
      }
    });
    if (needsHelp) {
      summary.total += 1;
    }
  });
  return summary;
};

const getFakeData = dispatch => () => {
  dispatch({type: 'fakeData', payload: fakeData});
};

const getMessagesMetadata = dispatch => async code => {
  try {
    const allData = await getMessages(code);
    const messageMetadata = {};
    const messageHub = allData.map(message => {
      const {uid} = message;
      messageMetadata[uid] = message;
      return uid;
    });
    const payload = {messageMetadata, messageHub};
    dispatch({type: 'getMessages', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const getMetadata = dispatch => async uid => {
  try {
    const messageMetadata = await getMessage(uid);
    const payload = {messageMetadata, uid};
    dispatch({type: 'getMetadata', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const setMessageMetadata = dispatch => async (uid, update) => {
  try {
    await updateMessageMetadata(uid, update);
    const payload = {uid, update};
    dispatch({type: 'setMetadata', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const getAtRiskMessages = dispatch => async uid => {
  try {
    const unsubscirber = getMessageChainListener(uid, dispatch);
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const sendAtRiskMessage = dispatch => async (uid, message) => {
  try {
    await sendMessage(uid, message);
    trackEvent('messageSent', {uid, message});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const updateDashboardUser = dispatch => async (uid, update) => {
  try {
    await updateDashboardProfile(uid, update);
    const payload = {uid, update};
    dispatch({
      type: 'updateDashboardProfile',
      payload,
    });
    return true;
  } catch (err) {
    handleErrors(err, dispatch);
    return false;
  }
};

const handleErrors = (err, dispatch) => {
  switch (err.code) {
    default:
      dispatch({
        type: 'error',
        payload: 'The email or password is incorrect.',
      });
      break;
  }
};

const getUserNotes = dispatch => async uid => {
  try {
    const notes = await getNotes(uid);
    const payload = {uid, notes};
    dispatch({type: 'getNotes', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const addNote = dispatch => async (uid, update) => {
  try {
    await setNote(uid, update);
    const payload = {uid, update};
    dispatch({type: 'addNote', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const removeNote = dispatch => async (uid, note) => {
  try {
    await deleteNote(uid, note);
    const payload = {uid, note};
    dispatch({type: 'deleteNote', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const updateNote = dispatch => async (uid, update) => {
  try {
    await setNote(uid, update);
    const payload = {uid, update};
    dispatch({type: 'updateNote', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const defaultValues = {
  messageHub: [],
  messageMetadata: {},
  messageChain: {},
  notes: {},
  team: null,
  notificationSettings: {
    dashboardWeeklyUpdates: {
      title: 'WEEKLY UPDATES',
      description:
        'Receive a snapshot of your dashboard in your email every Monday. ',
      push: false,
      email: false,
      text: false,
    },
    dashboardAtRiskUpdates: {
      title: 'AT-RISK UPDATES',
      description:
        'Receive an email each time an at-risk students requests help.',
      push: false,
      email: false,
      text: false,
    },
  },
  loaded: false,
  users: [],
  atRiskUsers: [],
  atRisk: 0,
  askHelp: 0,
  savingsSet: 0,
  budgetSet: 0,
  debtSet: 0,
  linked: 0,
  notLinked: 0,
  notOnboarded: 0,
  activeLinked: 0,
  moneyWon: 0,
  averageNetWorth: 0,
  averageAtRiskNetWorth: 0,
  potentialSavings: [],
  lastRefresh: null,
  deleted: 0,

  moduleInteraction: {},
  appProgress: {},
  atRiskPillarBreakdown: {
    checking: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Checking/Budget',
    },
    credit: {
      total: 0,
      limit: 0,
      utilization: 0,
      average: 0,
      count: 0,
      users: 0,
      name: 'Credit',
    },
    debt: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Debt',
    },
    investment: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      name: 'Investments',
    },
    savings: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Savings',
    },
    other: {total: 0, average: 0, count: 0, name: 'Other'},
    undefined: {total: 0, average: 0, count: 0, goalCreated: 0},
  },
  pillarBreakdown: {
    checking: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Checking/Budget',
    },
    credit: {
      total: 0,
      limit: 0,
      utilization: 0,
      average: 0,
      count: 0,
      users: 0,
      name: 'Credit',
    },
    debt: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Debt',
    },
    investment: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      name: 'Investments',
    },
    savings: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      goalCreated: 0,
      name: 'Savings',
    },
    other: {total: 0, average: 0, count: 0, users: 0, name: 'Other'},
    undefined: {total: 0, average: 0, count: 0, users: 0, goalCreated: 0},
  },
  atRiskBreakdown: {},
  accountTypeSum: [],
  institutionBreakdown: [],
  genderBreakdown: [],
  majorBreakdown: [],
  birthYearBreakdown: [],
  employmentBreakdown: [],

  atRiskByDay: [],
  signUpsByDay: [],
  signUpsSummation: [],
  transactionCategoriesPerMonth: [],
  transactionTypesPerMonth: [],
  atRiskTransactionCategoriesPerMonth: [],
  atRiskTransactionTypesPerMonth: [],
};

export const {Provider, Context} = createDataContext(
  dashboardReducer,
  {
    fetchTeam,
    updateTeam,
    updateSettings,
    getProfiles,
    getAtRisk,
    getFakeData,
    getMessagesMetadata,
    getMetadata,
    setMessageMetadata,
    getAtRiskMessages,
    sendAtRiskMessage,
    updateDashboardUser,
    getUserNotes,
    addNote,
    removeNote,
    updateNote,
  },
  defaultValues,
);

const objectToSortedArray = obj => {
  const array = Object.keys(obj)
    .map(key => {
      return {name: key, value: obj[key]};
    })
    .sort((a, b) => a.value < b.value);
  return array;
};

const objectToArray = obj => {
  const array = Object.keys(obj).map(key => {
    return {name: key, value: obj[key]};
  });

  return array;
};

const complexObjectToArray = obj => {
  const array = Object.keys(obj).map(key => {
    return {name: key, ...obj[key]};
  });

  return array;
};

// TAKES TWO STRINGS IN THE FORMAT YYYY-MM-DD
const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate);
  const end = new Date(endDate);

  const dates = [];

  while (date <= end) {
    dates.push(dateToString(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

const filterFinsiteful = uid => {
  if (
    uid === 'CLokOplml1gGhPhZlkLcSfAmPer1' || // Charlie
    uid === 'DyQEftKUwJPlQFRmrpdLciW1ryg1' || // Jack
    uid === 'a326FZ9iuDUGarpEVnTdv6U0Olg1' || // Jack 2
    uid === '80ajNgZ9hLT7fAtck4dM1ilOubM2' || // Axel
    uid === 'IaEZw1nMK9eQ9oN1DNLO6rIXl4i1' || // Test Account
    uid === 'jmG5Kg1X8SgUZLDZJbTkhocLyW52' || // Kirtan
    uid === 'i8kHFXQJDJRziUISOedlK53vyzj2' || // Dominique
    uid === 'UMXyGNfJaOSijmf5IS2MyjnyrXD3' || // Lucas
    uid === 'FPhffL1w3pgx7pwG5SWUFlobObh2' || // Satish
    uid === '6rtyMmWIeDf3Ph0AK1eH24BKSEH2' // Community@test.com)
  ) {
    return true;
  }
  return false;
};

const fakeData = {
  users: [],
  userCount: 10238,
  atRiskUsers: [
    {
      // actionTaken: '2022-12-06',
      // atRiskResolved: '2022-12-06',
      dateCreated: new Date(),
      shareAtRiskAccepted: '2023-01-08',
      atRiskIssue: 'Car / Transportation',
      uid: 'CLokOplml1gGhPhZlkLcSfAmPer1',
      atRiskDate: '2022-10-08',
      atRiskData: {cashFlow: {risk: true}},
      firstName: 'Charlie',
      lastName: 'Caswell  ',
      email: 'chcaswell10@gmail.com',
      major: 'CSC',
      phoneNumber: 'xxx-xxx-xxxx',
      studentID: '123456',
    },
    {
      // actionTaken: '2022-12-06',
      // atRiskResolved: '2022-12-06',
      dateCreated: new Date(),
      shareAtRiskAccepted: '2023-01-07',
      uid: '80ajNgZ9hLT7fAtck4dM1ilOubM2',
      atRiskDate: '2022-10-08',
      atRiskData: {cashFlow: {risk: true}},
      firstName: 'Axel',
      lastName: 'Fries  ',
      email: 'axfries40@gmail.com',
      major: 'ECON??',
      phoneNumber: 'xxx-xxx-xxxx',
      studentID: '123456',
    },
    {
      uid: '00001111',
      atRiskDate: '2022-10-08',
      atRiskData: {cashFlow: {risk: true}},
      firstName: 'abc',
      lastName: 'xyz',
      email: 'abc@nau.edu',
      major: 'Nursing',
      phoneNumber: 'xxx-xxx-xxxx',
      studentID: '123456',
    },
    {
      uid: '00002222',
      atRiskDate: '2022-09-30',
      atRiskData: {creditUtilization: {risk: true}, interest: {risk: true}},
      firstName: 'abc',
      lastName: 'xyz',
      email: 'abc@nau.edu',
      major: 'Nursing',
      phoneNumber: 'xxx-xxx-xxxx',
      studentID: '123456',
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    // {
    //   uid: '00003333',
    //   atRiskDate: '2022-08-16',
    //   atRiskData: {netWorth: {risk: true}},
    // },
    // {
    //   uid: '000004444',
    //   atRiskDate: '2022-09-22',
    //   atRiskData: {netWorth: {risk: true}, overdraft: {risk: true}},
    // },
  ],
  atRisk: 3467,
  askHelp: 2108,
  savingsSet: 19478,
  budgetSet: 21473,
  debtSet: 829,
  linked: 5983,
  notLinked: 4037,
  notOnboarded: 218,
  activeLinked: 5983,
  moneyWon: 1760,
  averageNetWorth: 257.72,
  averageAtRiskNetWorth: 98.5,
  potentialSavings: [
    {
      date: '2022-07',
      totalSavings: 273.24999999999994,
      users: 22,
      averageSavings: 12.420454545454543,
      maxSavings: 54.680000000000014,
      minSavings: 0,
    },
    {
      date: '2022-09',
      totalSavings: 125.26000000000002,
      users: 17,
      averageSavings: 7.368235294117648,
      maxSavings: 38.37,
      minSavings: 0,
    },
    {
      date: '2022-08',
      totalSavings: 344.5400000000005,
      users: 20,
      averageSavings: 17.227000000000025,
      maxSavings: 68.2500000000004,
      minSavings: 0.25,
    },
    {
      date: '2022-06',
      totalSavings: 367.36999999999966,
      users: 23,
      averageSavings: 15.97260869565216,
      maxSavings: 63.049999999999784,
      minSavings: 0,
    },
  ],
  lastRefresh: {seconds: 1663707831, nanoseconds: 313000000},
  moduleInteraction: {
    studentLoansModule: 529,
    creditModule: 710,
    collegeHacksModule: 640,
    investingModule: 550,
    managingIncomeModule: 302,
    jobsForStudentsModule: 548,
    nilModule: 510,
    emergencyFundModule: 647,
    lowCostActivitiesModule: 519,
    savingsModule: 720,
    contractorTaxesModule: 330,
    costOfLivingModule: 445,
    rentingApartmentModule: 422,
    retirementAccountsModule: 440,
    creditScoreModule: 620,
    whatToInvestInModule: 501,
    taxCreditModule: 393,
    taxesModule: 320,
    fafsaModule: 433,
  },
  appProgress: {
    notOnboarded: 218,
    incomplete: 4037,
    completeActive: 5153,
    completeInactive: 830,
  },
  atRiskPillarBreakdown: {
    checking: {
      total: 2842.47,
      average: 102.83,
      count: 1792,
      users: 1638,
      goalCreated: 829,
      name: 'Checking/Budget',
    },
    credit: {
      total: 14151.55,
      limit: 15800,
      utilization: 74.33,
      average: 3021.71,
      count: 1528,
      users: 1528,
      name: 'Credit',
    },
    debt: {
      total: 12565.51,
      average: 5195.585,
      count: 1602,
      users: 1602,
      goalCreated: 471,
      name: 'Debt',
    },
    investment: {
      total: 0,
      average: 0,
      count: 0,
      users: 0,
      name: 'Investments',
    },
    savings: {
      total: 69331.62000000001,
      average: 647.95777777778,
      count: 1468,
      users: 1376,
      goalCreated: 793,
      name: 'Savings',
    },
    other: {total: 0, average: 0, count: 0, name: 'Other'},
    undefined: {total: 0, average: 0, count: 0, goalCreated: 0},
  },
  pillarBreakdown: {
    checking: {
      total: 17534.200000000004,
      average: 123.45,
      count: 4899,
      users: 4493,
      goalCreated: 3596,
      name: 'Checking/Budget',
    },
    credit: {
      total: 20995.040000000008,
      limit: 66900,
      utilization: 31.382720478325872,
      average: 2342.67,
      count: 3793,
      users: 3793,
      name: 'Credit',
    },
    debt: {
      total: 41566.700000000004,
      average: 2227.837500000001,
      count: 13,
      users: 2894,
      goalCreated: 1389,
      name: 'Debt',
    },
    investment: {
      total: 0,
      average: 283.29,
      count: 0,
      users: 349,
      name: 'Investments',
    },
    savings: {
      total: 100051.49000000002,
      average: 1098.21,
      count: 4299,
      users: 4299,
      goalCreated: 3682,
      name: 'Savings',
    },
    other: {total: 0.2, average: 0.2, count: 1, users: 1, name: 'Other'},
    undefined: {total: 0, average: 0, count: 0, users: 0, goalCreated: 0},
  },
  atRiskBreakdown: {
    possible: 1932,
    total: 1502,
    netWorth: 829,
    creditUtilization: 348,
    creditDebt: 293,
    cashFlow: 1203,
    overdraft: 938,
    interest: 682,
    new: 0,
  },
  accountTypeSum: [
    {name: 'checking', value: 5537},
    {name: 'savings', value: 4595},
    {name: 'debt', value: 2039},
    {name: 'credit', value: 3029},
    {name: 'other', value: 0},
    {name: 'investment', value: 0},
  ],
  institutionBreakdown: [],
  genderBreakdown: [
    {name: 'Female', value: 8140},
    {name: 'Male', value: 2143},
  ],
  majorBreakdown: [
    {name: 'Nursing', value: 1223},
    {name: 'Business Management And Administration', value: 523},
    {name: 'N/A', value: 422},
    {name: 'Health And Medical Administrative Services', value: 433},
    {name: 'General Education', value: 332},
    {name: 'General Business', value: 264},
    {name: 'Social Work', value: 239},
    {name: 'Early Childhood Education', value: 203},
    {name: 'Other Foreign Languages', value: 240},
    {name: 'Clinical Psychology', value: 120},
    {name: 'Elementary Education', value: 181},
    {name: 'Miscellaneous Business & Medical Administration', value: 139},
    {name: 'Cosmetology Services And Culinary Arts', value: 119},
  ],
  birthYearBreakdown: [
    {name: '2000', value: 7022},
    {name: '1990', value: 1290},
    {name: '1980', value: 299},
    {name: '1960', value: 102},
    {name: '1970', value: 39},
  ],
  employmentBreakdown: [
    {name: 'Part-time job', value: 4422},
    {name: 'No job', value: 2563},
    {name: 'Full-time job', value: 3298},
  ],
  atRiskByDay: [],
  signUpsByDay: [
    {name: '2022-01-05', value: 1},
    {name: '2022-01-06', value: 1},
    {name: '2022-02-08', value: 2},
    {name: '2022-02-09', value: 1},
    {name: '2022-02-28', value: 9},
    {name: '2022-03-02', value: 2},
    {name: '2022-03-04', value: 1},
    {name: '2022-03-26', value: 1},
    {name: '2022-03-28', value: 1},
    {name: '2022-04-06', value: 1},
    {name: '2022-04-14', value: 1},
    {name: '2022-05-10', value: 20},
    {name: '2022-05-11', value: 10},
    {name: '2022-05-14', value: 1},
    {name: '2022-05-16', value: 3},
    {name: '2022-05-17', value: 1},
    {name: '2022-05-23', value: 1},
    {name: '2022-06-02', value: 1},
    {name: '2022-06-08', value: 1},
    {name: '2022-06-16', value: 1},
    {name: '2022-08-02', value: 1},
    {name: '2022-08-09', value: 1},
    {name: '2022-08-16', value: 1},
    {name: '2022-08-18', value: 1},
    {name: '2022-08-23', value: 1},
  ],
  signUpsSummation: [
    {date: '2022-01-05', value: 19},
    {date: '2022-01-06', value: 28},
    {date: '2022-01-07', value: 29},
    {date: '2022-01-08', value: 30},
    {date: '2022-01-09', value: 42},
    {date: '2022-01-10', value: 51},
    {date: '2022-01-11', value: 59},
    {date: '2022-01-12', value: 65},
    {date: '2022-01-13', value: 85},
    {date: '2022-01-14', value: 102},
    {date: '2022-01-15', value: 150},
    {date: '2022-01-16', value: 160},
    {date: '2022-01-17', value: 172},
    {date: '2022-01-18', value: 202},
    {date: '2022-01-19', value: 260},
    {date: '2022-01-20', value: 310},
    {date: '2022-01-21', value: 450},
    {date: '2022-01-22', value: 464},
    {date: '2022-01-23', value: 512},
    {date: '2022-01-24', value: 617},
    {date: '2022-01-25', value: 641},
    {date: '2022-01-26', value: 660},
    {date: '2022-01-27', value: 670},
    {date: '2022-01-28', value: 700},
    {date: '2022-01-29', value: 812},
    {date: '2022-01-30', value: 1020},
    {date: '2022-01-31', value: 1200},
    {date: '2022-02-01', value: 1500},
    {date: '2022-02-02', value: 1600},
    {date: '2022-02-03', value: 1612},
    {date: '2022-02-04', value: 1690},
    {date: '2022-02-05', value: 1800},
    {date: '2022-02-06', value: 1902},
    {date: '2022-02-07', value: 2059},
    {date: '2022-02-08', value: 2400},
    {date: '2022-02-09', value: 2720},
    {date: '2022-02-10', value: 2842},
    {date: '2022-02-11', value: 2938},
    {date: '2022-02-12', value: 3185},
    {date: '2022-02-13', value: 3499},
    {date: '2022-02-14', value: 3600},
    {date: '2022-02-15', value: 3650},
    {date: '2022-02-16', value: 3702},
    {date: '2022-02-17', value: 3872},
    {date: '2022-02-18', value: 3972},
    {date: '2022-02-19', value: 4055},
    {date: '2022-02-20', value: 4422},
    {date: '2022-02-21', value: 5400},
    {date: '2022-02-22', value: 5633},
    {date: '2022-02-23', value: 5682},
    {date: '2022-02-24', value: 5736},
    {date: '2022-02-25', value: 6922},
    {date: '2022-02-26', value: 7289},
    {date: '2022-02-27', value: 7462},
    {date: '2022-02-28', value: 7655},
    {date: '2022-03-01', value: 7599},
    {date: '2022-03-02', value: 7620},
    {date: '2022-03-03', value: 7699},
    {date: '2022-03-04', value: 7828},
    {date: '2022-03-05', value: 7901},
    {date: '2022-03-06', value: 8022},
    {date: '2022-03-07', value: 8320},
    {date: '2022-03-08', value: 8492},
    {date: '2022-03-09', value: 8569},
    {date: '2022-03-10', value: 8647},
    {date: '2022-03-11', value: 8723},
    {date: '2022-03-12', value: 8820},
    {date: '2022-03-13', value: 8903},
    {date: '2022-03-13', value: 9203},
    {date: '2022-03-14', value: 9350},
    {date: '2022-03-15', value: 9427},
    {date: '2022-03-16', value: 9598},
    {date: '2022-03-17', value: 9652},
    {date: '2022-03-18', value: 9723},
    {date: '2022-03-19', value: 9832},
    {date: '2022-03-20', value: 9845},
    {date: '2022-03-21', value: 9982},
    {date: '2022-03-22', value: 10020},
    {date: '2022-03-23', value: 10029},
    {date: '2022-03-24', value: 10039},
    {date: '2022-03-25', value: 10102},
    {date: '2022-03-26', value: 10250},
    {date: '2022-03-27', value: 10283},
  ],
  transactionCategoriesPerMonth: [
    {
      date: '2022-06',
      Transportation: '56.99',
      Food: '266.57',
      'Living Expenses': '44.04',
    },
    {
      date: '2022-07',
      Transportation: '32.55',
      Food: '275.88',
      'Living Expenses': '56.50',
    },
    {
      date: '2022-08',
      Transportation: '98.12',
      Food: '313.19',
      'Living Expenses': '78.58',
    },
    {
      date: '2022-09',
      Transportation: '9.86',
      Food: '116.64',
      'Living Expenses': '15.87',
    },
  ],
  transactionTypesPerMonth: [
    {
      date: '2022-06',
      count: 1933,
      users: 23,
      spending: '4988.86',
      savings: '7227.04',
      investment: '8.70',
      income: '5203.40',
      bill: '0.00',
      ignore: '0.00',
      transfer: '973.95',
      creditCardPayoff: '664.82',
      creditCardReceived: '516.16',
    },
    {
      date: '2022-07',
      count: 1536,
      users: 22,
      spending: '7562.36',
      savings: '609.04',
      investment: '9.09',
      income: '5467.28',
      bill: '0.00',
      ignore: '0.00',
      transfer: '780.40',
      creditCardPayoff: '999.75',
      creditCardReceived: '463.06',
    },
    {
      date: '2022-08',
      count: 1875,
      users: 20,
      spending: '6128.06',
      savings: '522.82',
      investment: '10.00',
      income: '4020.82',
      bill: '0.00',
      ignore: '0.00',
      transfer: '1389.72',
      creditCardPayoff: '696.20',
      creditCardReceived: '417.61',
    },
    {
      date: '2022-09',
      count: 717,
      users: 17,
      spending: '2921.58',
      savings: '241.64',
      investment: '11.76',
      income: '2084.44',
      bill: '0.00',
      ignore: '0.00',
      transfer: '533.21',
      creditCardPayoff: '833.29',
      creditCardReceived: '670.44',
    },
  ],
  atRiskTransactionCategoriesPerMonth: [
    {
      date: '2022-06',
      Transportation: '66.14',
      Food: '268.53',
      'Living Expenses': '41.19',
    },
    {
      date: '2022-07',
      Transportation: '33.16',
      Food: '249.12',
      'Living Expenses': '55.83',
    },
    {
      date: '2022-08',
      Transportation: '36.78',
      Food: '265.68',
      'Living Expenses': '11.85',
    },
    {
      date: '2022-09',
      Transportation: '11.97',
      Food: '92.45',
      'Living Expenses': '12.80',
    },
  ],
  atRiskTransactionTypesPerMonth: [
    {
      date: '2022-06',
      count: 1458,
      users: 15,
      spending: '6066.20',
      savings: '10741.44',
      investment: '0.00',
      income: '5112.56',
      bill: '0.00',
      ignore: '0.00',
      transfer: '1016.19',
      creditCardPayoff: '81.45',
      creditCardReceived: '107.41',
    },
    {
      date: '2022-07',
      count: 1169,
      users: 15,
      spending: '9227.09',
      savings: '821.15',
      investment: '0.00',
      income: '5089.05',
      bill: '0.00',
      ignore: '0.00',
      transfer: '976.88',
      creditCardPayoff: '60.59',
      creditCardReceived: '59.06',
    },
    {
      date: '2022-08',
      count: 1433,
      users: 15,
      spending: '3435.35',
      savings: '673.93',
      investment: '0.00',
      income: '2238.71',
      bill: '0.00',
      ignore: '0.00',
      transfer: '1440.46',
      creditCardPayoff: '50.76',
      creditCardReceived: '22.19',
    },
    {
      date: '2022-09',
      count: 530,
      users: 14,
      spending: '1736.59',
      savings: '243.42',
      investment: '0.00',
      income: '1319.25',
      bill: '0.00',
      ignore: '0.00',
      transfer: '608.90',
      creditCardPayoff: '51.23',
      creditCardReceived: '26.14',
    },
  ],
  riskCount: 15,
  budgetCreated: 3,
  savingsCreated: 1,
  debtCreated: 1,
  raffleWinnings: 1860,
  netWorth: 53044.33,
  atRiskNetWorth: 64473.73000000003,
  usersWithAccounts: 32,
};
