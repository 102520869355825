import createDataContext from './createDataContext';
import {
  collection,
  addDoc,
  query,
  setDoc,
  getDoc,
  doc,
  where,
} from 'firebase/firestore';
import {
  getClientProfile,
  getFinancialProfile,
  getTeamSettings,
  getUniversalProfile,
  storage,
  updateDashboardProfile,
  updateTeamSettings,
  updateUniversalProfile,
} from '../api/firestore';
import {db} from '../api/firebase';
import GlobalConstants from '../components/GlobalConstants';
import {identifyUser} from '../api/segment';
import {
  changeEmail,
  changePassword,
  createUser,
  login,
  sendResetPassword,
  signout,
} from '../api/auth';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'signin':
      const {universal} = action.payload;
      if (universal) {
        const {uid, firstName, lastName, email, organizationCode, studentID} =
          universal;
        identifyUser(uid, {
          firstName,
          lastName,
          email,
          organizationCode,
          studentID,
        });
      }
      return {...state, ...action.payload};
    case 'badSignin':
      return {
        ...state,
      };
    case 'updateAuth':
      return {...state, ...action.payload};
    case 'error':
      return {...state, error: action.payload};
    case 'updateProfile':
      const updatedUniversal = {...state.universal, ...action.payload.update};
      sessionStorage.setItem('universal', JSON.stringify(updatedUniversal));
      return {
        ...state,
        universal: updatedUniversal,
      };
    case 'getSettings':
      return {...state, settings: action.payload.settings};
    case 'logout':
      return defaultValues;
    default:
      return state;
  }
};

// creates and account and signs into firebase
const signup = dispatch => async (email, password, data) => {
  try {
    const {firstName, lastName, code} = data;
    if (!email || !password || !firstName || !lastName) {
      throw new Error('incomplete-fields', 'incomplete-fields');
    }
    if (
      !password.match(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
      )
    ) {
      throw new Error('weak-password', 'weak-password');
    }
    const organizationCode = code || emailToOrgCode(email);

    const {user} = await createUser(email, password);
    const profile = {
      uid: user.uid,
      firstName,
      lastName,
      organizationCode,
      email,
      termsAccepted: true,
      dashboard: {
        dateCreated: new Date(),
        role: 'admin',
        active: true,
      },
    };
    await setDoc(doc(db, 'users', user.uid), profile, {merge: true});
    // UPDATE TEAM
    const team = await getTeamSettings(organizationCode);
    if (team) {
      const {members, notifications} = team;
      let invited = false;
      const updated = members.map(member => {
        if (email === member.email) {
          invited = true;
          return {...member, uid: user.uid, status: 'accepted'};
        }
        return member;
      });
      const updatedNotifications = {
        ...notifications,
        [user.uid]: {...GlobalConstants.defaultNotifications, email},
      };
      if (!invited) {
        updated.push({
          email,
          uid: user.uid,
          role: 'admin',
          status: 'accepted',
        });
      }
      await updateTeamSettings(organizationCode, {
        members: updated,
        notifications: updatedNotifications,
      });
    }
    const payload = {
      uid: user.uid,
      auth: user,
      universal: profile,
    };
    dispatch({type: 'signin', payload});
    sessionStorage.setItem('uid', user.uid);
    sessionStorage.setItem('universal', JSON.stringify(profile));
    sessionStorage.setItem('auth', JSON.stringify(user));
    localStorage.setItem('email', email);
    return {success: true, error: null};
  } catch (err) {
    // TODO: handle for if account already exists
    return handleErrors(err, dispatch);
  }
};

const updateAuth = dispatch => async auth => {
  dispatch({type: 'updateAuth', payload: {auth}});
  sessionStorage.setItem('auth', JSON.stringify(auth));
};

// creates and account and signs into firebase
const migrateAccount = dispatch => async (user, data) => {
  try {
    const {fullName, school, code} = data;
    const {email} = user;
    if (!fullName || !school) {
      throw new Error('incomplete-fields', 'incomplete-fields');
    }

    const profile = {
      uid: user.uid,
      fullName: fullName,
      school: school,
      loginCode: code,
      emailAddress: email,
      termsAccepted: true,
    };
    await setDoc(doc(db, 'clients', user.uid), profile);
    const payload = {
      uid: user.uid,
      auth: user,
      client: profile,
    };
    dispatch({type: 'signin', payload});
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

// signs in to firebase using the passed in email and password
// TODO: more serious credentials for making a client account
const signin = dispatch => async (email, password) => {
  try {
    const {user} = await login(email, password);

    const universal = await getUniversalProfile(user.uid);

    // const client = await getClientProfile(user.uid);

    // const financial = await getFinancialProfile(user.uid);

    const payload = {
      uid: user.uid,
      universal,
      auth: user,
      // client: client ? client : null,
      // financial: financial ? financial : null,
    };

    dispatch({type: 'signin', payload});
    sessionStorage.setItem('uid', user.uid);
    sessionStorage.setItem('universal', JSON.stringify(universal));
    sessionStorage.setItem('auth', JSON.stringify(user));
    localStorage.setItem('email', email);
    return {success: true, error: null, data: payload};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateProfile = dispatch => async (uid, update) => {
  try {
    await updateUniversalProfile(uid, update);
    const payload = {update};
    dispatch({
      type: 'updateProfile',
      payload,
    });
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updateEmail = dispatch => async (newEmail, oldEmail, password) => {
  try {
    const {uid} = changeEmail(newEmail, oldEmail, password);
    const update = {email: newEmail};
    await updateUniversalProfile(uid, update);
    await updateDashboardProfile(uid, update);
    const payload = {update};
    dispatch({
      type: 'updateProfile',
      payload,
    });
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const updatePassword = dispatch => async (email, newPassword, oldPassword) => {
  try {
    await changePassword(email, newPassword, oldPassword);
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const sendPasswordReset = dispatch => async email => {
  try {
    await sendResetPassword(email);
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

// logs out the user from firebase and sets user to null
const logout = dispatch => async () => {
  try {
    await signout();
    dispatch({type: 'logout'});
    sessionStorage.removeItem('uid');
    sessionStorage.removeItem('universal');
    sessionStorage.removeItem('auth');
    return {success: true, error: null};
  } catch (err) {
    return handleErrors(err, dispatch);
  }
};

const handleErrors = (err, dispatch) => {
  let error = 'Something went wrong.';
  switch (err.code) {
    case 'auth/weak-password':
      error = 'Please enter a password that meets the requirements.';
      break;
    case 'auth/invalid-email':
      error = 'You must provide a valid email.';
      break;
    case 'auth/invalid-password':
      error = 'You must provide a valid password.';
      break;
    case 'auth/email-already-in-use':
      error = 'That email is already in use.';
      break;
    case 'auth/user-not-found':
      error = 'No user found with that email.';
      break;
    case 'auth/wrong-password':
      error = 'The email or password is incorrect.';
      break;
    case 'auth/multi-factor-auth-required':
      error = 'Requires multi-factor authentication';
      break;
    case 'auth/missing-email':
      error = 'Missing or incorrect email.';
      break;
    default:
      if (err.message === 'incomplete-fields') {
        error = 'Fill out all fields to continue.';
      } else if (err.message === 'weak-password') {
        error = 'Use a stronger password to continue';
      }
      break;
  }
  dispatch({
    type: 'error',
    payload: error,
  });
  return {success: false, error};
};

const emailToOrgCode = email => {
  try {
    let domain = email.substring(email.indexOf('@') + 1);
    switch (domain) {
      case 'email.cpcc.edu':
      case 'cpcc.edu':
        return GlobalConstants.cpccCode;
      case 'beaufortccc.edu':
      case 'live.beaufortccc.edu':
        return GlobalConstants.bcccCode;
      case 'westvalley.edu':
        return GlobalConstants.wvccCode;
      case 'mitchellcc.edu':
        return GlobalConstants.mccCode;
      default:
        return null;
    }
  } catch (err) {
    return null;
  }
};

const defaultValues = {
  uid: sessionStorage.getItem('uid'),
  universal: JSON.parse(sessionStorage.getItem('universal')),
  auth: JSON.parse(sessionStorage.getItem('auth')),
  // TODO: update like above
  settings: null,
  settingsFetched: false,
  client: null,
  financial: null,
  error: null,
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    signin,
    migrateAccount,
    signup,
    sendPasswordReset,
    updateProfile,
    updateEmail,
    updatePassword,
    logout,
    updateAuth,
  },
  defaultValues,
);
