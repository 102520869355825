import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function Title(props) {
  const headerStyle = {
    color: '#9d9db0',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: '1.6px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    textAlign: 'center',
  }

  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom style={headerStyle}>
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
