import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import GlobalConstants from '../../components/GlobalConstants';
import {useAuth} from '../../hooks/useAuth';
import {useDashboard} from '../../hooks/useDashboard';

export const AccountNotificationsSettings = () => {
  const {
    state: {universal},
  } = useAuth();
  const {
    state: {team},
    updateTeam,
  } = useDashboard();

  const {uid, email} = universal;
  const {members = [], notifications = {}, name} = team || {};

  const organizationCode = team.organizationCode || null;

  const currentNotifications =
    notifications[uid] || GlobalConstants.defaultNotifications;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Email</Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
              }}>
              <div>
                <Typography variant="subtitle1">
                  New At Risk Students
                </Typography>
                <Typography color="textSecondary" sx={{mt: 1}} variant="body2">
                  Get notified when a new user is identified as at-risk.
                </Typography>
              </div>
              <Switch
                checked={currentNotifications.newAtRisk}
                onChange={async event => {
                  const updated = {
                    ...notifications,
                    [uid]: {
                      ...currentNotifications,
                      email,
                      newAtRisk: event.target.checked,
                    },
                  };
                  await updateTeam(organizationCode, {notifications: updated});
                }}
              />
            </Box>
            <Divider />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
              }}>
              <div>
                <Typography variant="subtitle1">New Messages</Typography>
                <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                  Get notified when there is a new message from a student.
                </Typography>
              </div>
              <Switch
                checked={currentNotifications.newMessage}
                onChange={async event => {
                  const updated = {
                    ...notifications,
                    [uid]: {
                      ...currentNotifications,
                      email,
                      newMessage: event.target.checked,
                    },
                  };
                  await updateTeam(organizationCode, {notifications: updated});
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* <Divider sx={{my: 3}} />
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Typography variant="h6">Phone notifications</Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3,
              }}>
              <div>
                <Typography variant="subtitle1">Project updates</Typography>
                <Typography color="textSecondary" sx={{mt: 1}} variant="body2">
                  Updates on stages of your project.
                </Typography>
              </div>
              <Switch />
            </Box>
          </Grid>
        </Grid> */}
      </CardContent>
    </Card>
  );
};
