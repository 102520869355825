import logoLight from '../../assets/logos/finsiteful-logo-light.png';
import logoDark from '../../assets/logos/finsiteful-logo-dark.png';
import logoHorizontalLight from '../../assets/logos/horizontal-logo-light.png';
import logoHorizontalDark from '../../assets/logos/horizontal-logo-dark.png';
import {useAuth} from '../../hooks/useAuth';

export const FinSitefulLogo = ({variant, style}) => {
  const dark = false;
  return (
    <img
      alt="Finsiteful"
      className="logo-image"
      style={{width: '68px', height: '68px', ...style}}
      src={dark ? logoLight : logoDark}
    />
  );
};

export const FinSitefulHorizontalLogo = ({variant, style}) => {
  const dark = false;
  return (
    <img
      alt="Finsiteful"
      className="logo-image"
      style={{width: '128px', height: '38px', ...style}}
      src={dark ? logoHorizontalDark : logoHorizontalDark}
    />
  );
};
