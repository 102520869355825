import {
  Box,
  Divider,
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import {useState} from 'react';
import {UserCircleIcon} from '../../assets/icons/user-circle';
import {useAuth} from '../../hooks/useAuth';
import LightThemeIcon from '../../assets/images/light-theme.svg';
import DarkThemeIcon from '../../assets/images/dark-theme.svg';
import Spacer from '../../components/Common/Spacer';
import {SideDrawer} from '../../components/Layout/side-drawer';
import {EditEmail, EditPassword} from './AuthModals';

const themes = [
  {
    label: 'Light',
    value: 'light',
    icon: LightThemeIcon,
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: DarkThemeIcon,
  },
];

export const AccountGeneralSettings = props => {
  const {
    state: {uid, universal, auth},
    updateProfile,
  } = useAuth();

  const [authModal, setAuthModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [firstName, setFirstName] = useState(universal.firstName || '');
  const [lastName, setLastName] = useState(universal.lastName || '');
  // const [values, setValues] = useState(
  //   universal.settings || constants.defaultSettings,
  // );

  return (
    <Box sx={{mt: 4}} {...props}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              {/* <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}>
                <Avatar
                  src={
                    universal.avatar ||
                    '/static/mock-images/avatars/avatar-anika_visser.png'
                  }
                  sx={{
                    height: 64,
                    mr: 2,
                    width: 64,
                  }}>
                  <UserCircleIcon fontSize="small" />
                </Avatar>
                <Button>Change</Button>
              </Box> */}
              <Box
                sx={{
                  display: 'flex',
                  mt: 3,
                  alignItems: 'center',
                }}>
                <TextField
                  defaultValue={universal.firstName}
                  label="First Name"
                  size="small"
                  value={firstName}
                  onChange={evt => {
                    setFirstName(evt.target.value);
                  }}
                  sx={{
                    flexGrow: 1,
                    mr: 3,
                  }}
                />
                <Button
                  disabled={firstName === universal.firstName}
                  onClick={async () => {
                    await updateProfile(uid, {firstName});
                  }}>
                  Save
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  mt: 3,
                  alignItems: 'center',
                }}>
                <TextField
                  defaultValue={universal.lastName}
                  label="Last Name"
                  size="small"
                  value={lastName}
                  onChange={evt => {
                    setLastName(evt.target.value);
                  }}
                  sx={{
                    flexGrow: 1,
                    mr: 3,
                  }}
                />
                <Button
                  disabled={lastName === universal.lastName}
                  onClick={async () => {
                    await updateProfile(uid, {lastName});
                  }}>
                  Save
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  mt: 3,
                  alignItems: 'center',
                }}>
                <TextField
                  disabled
                  defaultValue={auth.email}
                  label="Email Address"
                  required
                  size="small"
                  sx={{
                    flexGrow: 1,
                    mr: 3,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderStyle: 'dashed',
                    },
                  }}
                />
                <Button
                  onClick={() => {
                    setAuthModal(true);
                    setIndex(0);
                  }}>
                  Edit
                </Button>
              </Box>
              <Button
                onClick={() => {
                  setAuthModal(true);
                  setIndex(1);
                }}>
                Change Password
              </Button>
              {/* <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mt: 2,
                }}>
                {themes.map(theme => {
                  const {label, icon: Icon, value} = theme;

                  return (
                    <div key={value}>
                      <Box
                        onClick={() => {
                          setValues({...values, theme: value, mode: value});
                        }}
                        sx={{
                          borderColor:
                            values.theme === value ? 'primary.main' : 'divider',
                          borderRadius: 1,
                          borderStyle: 'solid',
                          borderWidth: 2,
                          cursor: 'pointer',
                          flexGrow: 1,
                          fontSize: 0,
                          m: 1,
                          overflow: 'hidden',
                          p: 1,
                          '& svg': {
                            height: 'auto',
                            width: '100%',
                          },
                        }}>
                        <img src={Icon} style={{maxWidth: '100%'}} />
                      </Box>
                      <Typography
                        align="center"
                        sx={{mt: 1}}
                        variant="subtitle2">
                        {label}
                      </Typography>
                    </div>
                  );
                })}
                <Button
                  onClick={async () => {
                    const update = {...user.settings, ...values};
                    await updateProfile(uid, {
                      settings: update,
                    });
                  }}>
                  Save
                </Button>
              </Box> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SideDrawer
        open={authModal}
        onClose={() => setAuthModal(false)}
        header={index === 0 ? 'Edit Email' : 'Edit Password'}>
        {index === 0 && <EditEmail setShow={setAuthModal} />}
        {index === 1 && <EditPassword setShow={setAuthModal} />}
      </SideDrawer>
      {/* <Card sx={{mt: 4}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Public profile</Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}>
                <div>
                  <Typography variant="subtitle1">
                    Make Contact Info Public
                  </Typography>
                  <Typography
                    color="textSecondary"
                    sx={{mt: 1}}
                    variant="body2">
                    Means that anyone viewing your profile will be able to see
                    your contacts details.
                  </Typography>
                </div>
                <Switch />
              </Box>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 3,
                }}>
                <div>
                  <Typography variant="subtitle1">Available to hire</Typography>
                  <Typography
                    color="textSecondary"
                    sx={{mt: 1}}
                    variant="body2">
                    Toggling this will let your teammates know that you are
                    available for acquiring new projects.
                  </Typography>
                </div>
                <Switch defaultChecked />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      {/* <Card sx={{mt: 4}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Delete Account</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography sx={{mb: 3}} variant="subtitle1">
                Delete your account and all of your source data. This is
                irreversible.
              </Typography>
              <Button color="error" variant="outlined">
                Delete account
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      <Spacer style={{marginBottom: 200}} />
    </Box>
  );
};
