import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../Common/Title';
import {CustomPieChart} from './Charts';
import Link from '@mui/material/Link';
import Header from '../Common/Header';
import {Card, CardHeader, Divider, Grid, Tooltip} from '@mui/material';
import {InfoCircleIcon} from '../../assets/icons/info-circle';
// import styles from '../../styles.css';

function PieChartTile({header, info, slice, data, height}) {
  const boxShadowStyle = {
    boxShadow:
      '0 22px 25px 0 rgb(228 228 234 / 22%), 0 9px 23px 0 rgb(228 228 234 / 50%)',
    '&:hover': {
      boxShadow:
        '0 22px 32px 0 rgb(228 228 234 / 56%), 0 9px 28px 0 rgb(228 228 234 / 64%)',
    },
  };

  return (
    <Card sx={{}}>
      <CardHeader
        title={header}
        action={
          info && (
            <Tooltip title={info} sx={{mr: 2}}>
              <InfoCircleIcon />
            </Tooltip>
          )
        }
      />
      <Divider />
      <CustomPieChart data={data} slice={slice} height={height} />
    </Card>
  );
}

export default PieChartTile;
