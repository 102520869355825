import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import {
  convertToDate,
  currentLocalTimeToString,
  prettifyMediumDate,
  prettifyTime,
  prettifyShortFullDate,
} from '../../api/dateManager';
import {BackButton, PrimaryButton} from '../../components/Common/Buttons';
import GlobalConstants from '../../components/GlobalConstants';
import Messenger from '../../components/Messages/Messenger';
import mentalHealthLottie from '../../assets/lottie/mental-wellbeing.json';
import {useAuth} from '../../hooks/useAuth';
import {useDashboard} from '../../hooks/useDashboard';
import {trackEvent} from '../../api/segment';
import {Box, Container} from '@mui/system';
import {useLocation, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {v4} from 'uuid';
import {useTheme} from '@emotion/react';
import Copyright from '../../components/Common/Copyright';
import {XIcon} from '../../assets/icons/x';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const UserDetail = () => {
  const navigate = useNavigate();
  const {
    state: {user: originalUser, metadata: originalMetadata},
  } = useLocation();

  const [user, setUser] = useState(originalUser || {});
  const [metadata, setMetadata] = useState(originalMetadata || {});

  const {firstName} = user;
  const {student} = metadata;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="lg">
          <Box sx={{mb: 4}}>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="h4">
                {firstName || student.firstName}
              </Typography>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}>
                <XIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid container spacing={4}>
            {Object.keys(user).length ? (
              <Grid item xs={12} md={12} lg={12}>
                <StudentInfo
                  user={user}
                  setUser={setUser}
                  metadata={metadata}
                />
              </Grid>
            ) : null}
            <Grid item xs={8} md={8} lg={8}>
              <Messenger
                metadata={metadata}
                user={user}
                resources={GlobalConstants.fakeResources}
              />
            </Grid>
            <Grid item xs={8} md={4} lg={4}>
              <Notes user={user} metadata={metadata} />
            </Grid>
          </Grid>
        </Container>
        <Copyright sx={{pt: 4}} />
      </Box>
    </>
  );
};

const StudentInfo = ({user, setUser}) => {
  const {
    firstName = null,
    lastName = null,
    studentID = null,
    email = null,
    major = null,
    phoneNumber = null,
    year = null,
    graduationDate = null,
    organizationCode = null,
    uid = null,
    actionTaken = null,
    atRiskResolved = null,
    atRiskIssue = null,
    accounts = [],
    linked = false,
    dateCreated,
  } = user || {};

  const {updateDashboardUser} = useDashboard();

  const [show, setShow] = useState(false);

  const typed = convertToDate(dateCreated);

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => {
          setShow(false);
        }}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: '600',
            padding: '16px',
            textAlign: 'center',
          }}>
          Great work!
        </Typography>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: mentalHealthLottie,
          }}
          height={112}
          width={112}
        />
        <Typography sx={{padding: '16px', textAlign: 'center'}}>
          You just helped an at-risk student! Keep up the good work!
        </Typography>
        <PrimaryButton
          text="CLOSE"
          onClick={() => {
            setShow(false);
          }}
        />
        <Typography sx={{padding: '16px', textAlign: 'center'}}>
          You can view the students you've helped at the bottom of this screen.
        </Typography>
      </Dialog>
      <Card>
        <CardHeader
          title={
            firstName && lastName ? firstName + ' ' + lastName : 'John Doe'
          }
          subheader={`ID: ${studentID}`}
          action={
            !atRiskResolved && (
              <Button
                onClick={async () => {
                  setShow(true);
                  const today = currentLocalTimeToString();
                  setUser({...user, atRiskResolved: today});
                  await updateDashboardUser(uid, {
                    atRiskResolved: today,
                  });
                  trackEvent('atRiskMarkedAsHelped', {user});
                }}>
                MARK AS HELPED
              </Button>
            )
          }
        />
        <Divider />
        <Grid container justifyContent="space-evenly">
          <Grid item md={4} xs={4}>
            <DetailedField text={email} header="Email" />
          </Grid>
          <Grid item md={4} xs={4}>
            <DetailedField text={phoneNumber || 'N/A'} header="Phone Number" />
          </Grid>
          <Grid item md={4} xs={4}>
            <DetailedField text={prettifyMediumDate(typed)} header="Joined" />
          </Grid>
          <Grid item md={4} xs={4}>
            <DetailedField
              text={graduationDate || 'Spring 2022'}
              header="Grad Date"
            />
          </Grid>
          <Grid item md={4} xs={4}>
            <DetailedField text={major || 'N/A'} header="Major" />
          </Grid>
          <Grid item md={4} xs={4}>
            <DetailedField text={accounts.length} header="Accounts" />
          </Grid>
        </Grid>

        {atRiskIssue && (
          <div>
            <Divider />
            <DetailedField text={atRiskIssue || 'N/A'} header="Issue" />
          </div>
        )}
      </Card>
    </div>
  );
};

const Notes = ({user, metadata}) => {
  const {
    state: {universal},
  } = useAuth();

  const {
    state: {notes},
    getUserNotes,
    addNote,
    updateNote,
    removeNote,
  } = useDashboard();

  const {uid: studentUID} = user;
  const {uid: studentMessageUID} = metadata;
  const unifiedUID = studentUID || studentMessageUID;
  const {uid, firstName, lastName} = universal;

  const currentNotes = notes[unifiedUID] || [];

  const [index, setIndex] = useState(0);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [current, setCurrent] = useState(null);
  const [editBody, setEditBody] = useState('');
  const [editTitle, setEditTitle] = useState('');

  useEffect(() => {
    if (!currentNotes.length) {
      getUserNotes(unifiedUID);
    }
  }, []);

  const saveNote = async () => {
    setIndex(0);
    setTitle('');
    setBody('');
    if (title || body) {
      const note = {
        uid: v4(),
        title,
        body,
        created: new Date(),
        updated: new Date(),
        author: {uid, firstName, lastName},
      };
      await addNote(unifiedUID, note);
      trackEvent('addedNote', {note});
    }
  };

  const editNote = async () => {
    setIndex(0);
    setEditTitle('');
    setEditBody('');
    const note = {
      ...current,
      title: editTitle,
      body: editBody,
      updated: new Date(),
    };
    await updateNote(unifiedUID, note);
    trackEvent('editedNote', {note});
  };

  const deleteNote = async () => {
    setIndex(0);
    setEditTitle('');
    setEditBody('');
    await removeNote(unifiedUID, current);
    trackEvent('deletedNote', {note: current});
  };

  return (
    <>
      <Card sx={{maxHeight: '608px'}}>
        {index === 0 && (
          <>
            <CardHeader
              title="Notes"
              action={
                <IconButton
                  onClick={() => {
                    setIndex(1);
                  }}>
                  <AddIcon color="primary" />
                </IconButton>
              }
            />
            <Divider />
            <Box>
              <List
                sx={{
                  height: '365px',
                }}>
                {currentNotes.map(note => {
                  const {title, body, created, updated, author} = note;
                  const typed = convertToDate(updated);
                  return (
                    <ListItem
                      divider
                      onClick={() => {
                        setIndex(3);
                      }}>
                      <ListItemButton
                        onClick={() => {
                          setCurrent(note);
                          setEditBody(note.body);
                          setEditTitle(note.title);
                        }}>
                        <ListItemText
                          sx={{fontWeight: 'bold'}}
                          secondary={`${
                            author.firstName
                          } - ${prettifyShortFullDate(typed)}, ${prettifyTime(
                            typed,
                          )}`}>
                          {title || body}
                        </ListItemText>
                        <ListItemIcon>
                          <ChevronRightIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        )}
        {index === 1 && (
          <Box>
            <Grid container justifyContent={'space-between'}>
              <Button startIcon={<ChevronLeftIcon />} onClick={saveNote}>
                Save
              </Button>
            </Grid>
            <TextField
              value={title}
              onChange={evt => {
                setTitle(evt.target.value);
              }}
              sx={{pl: 2, pr: 2}}
              variant="outlined"
              margin="normal"
              fullWidth
              id="title"
              label=""
              name="title"
              autoComplete="title"
              autoFocus
            />
            <Divider />
            <TextField
              size="large"
              multiline
              rows={12}
              value={body}
              onChange={evt => {
                setBody(evt.target.value);
              }}
              sx={{pl: 2, pr: 2}}
              variant="outlined"
              margin="normal"
              fullWidth
              id="note"
              label=""
              name="note"
              autoComplete="note"
            />
          </Box>
        )}
        {index === 3 && (
          <Box>
            <Grid container justifyContent={'space-between'}>
              <Button startIcon={<ChevronLeftIcon />} onClick={editNote}>
                {current.author.uid === uid ? 'Save' : 'Back'}
              </Button>
              {current.author.uid === uid && (
                <IconButton onClick={deleteNote}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              )}
            </Grid>
            <TextField
              disabled={current.author.uid !== uid}
              value={editTitle}
              onChange={evt => {
                setEditTitle(evt.target.value);
              }}
              sx={{pl: 2, pr: 2}}
              variant="outlined"
              margin="normal"
              fullWidth
              id="title"
              label=""
              name="title"
              autoComplete="title"
              autoFocus
            />
            <Divider />
            <TextField
              disabled={current.author.uid !== uid}
              size="large"
              multiline
              rows={12}
              value={editBody}
              onChange={evt => {
                setEditBody(evt.target.value);
              }}
              sx={{pl: 2, pr: 2}}
              variant="outlined"
              margin="normal"
              fullWidth
              id="note"
              label=""
              name="note"
              autoComplete="note"
            />
          </Box>
        )}
      </Card>
    </>
  );
};

const DetailedField = ({header, text}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
      }}>
      <Typography sx={{fontWeight: 'bold'}}>{text}</Typography>
      <Typography
        sx={{
          // textDecorationLine: 'underline',
          fontSize: 14,
          color: theme.palette.text.secondary,
        }}>
        {header}
      </Typography>
    </div>
  );
};

export default UserDetail;
