import {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {XIcon} from '../../assets/icons/x';
import LightThemeIcon from '../../assets/images/light-theme.svg';
import DarkThemeIcon from '../../assets/images/dark-theme.svg';
import PropTypes from 'prop-types';
import {useAuth} from '../../hooks/useAuth';
import {useTheme} from '@emotion/react';

export const SideDrawer = ({open, onClose, onOpen, children, header}) => {
  const theme = useTheme();

  const backgroundColor = theme.palette.background.default;

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      ModalProps={{sx: {zIndex: 1300}}}
      PaperProps={{
        sx: {minWidth: '400px', maxWidth: '600px', backgroundColor},
      }}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}>
        <Typography color="inherit" variant="h6">
          {header}
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <XIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        container
        sx={{
          color: 'primary',
          py: 4,
          px: 3,
        }}>
        {children}
      </Box>
    </Drawer>
  );
};

SideDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
