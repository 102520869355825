import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {PrimaryButton} from '../Common/Buttons';
import GlobalConstants from '../GlobalConstants';
import {v4} from 'uuid';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import {Context as DashboardContext} from '../../contexts/DashboardContext';
import {
  currentLocalTimeToString,
  dateToString,
  prettifyMediumDate,
  prettifyTime,
} from '../../api/dateManager';
import {trackEvent} from '../../api/segment';
import AutoLinkText from 'react-autolink-text2';
import useVisible from '../../hooks/useVisible';
import {useTheme} from '@emotion/react';

const Messenger = ({metadata, user, resources, onSend}) => {
  const [useOnScreen] = useVisible();
  const {palette} = useTheme();

  const {
    uid: originalUID = null,
    organizationCode: originalCode = null,
    firstName = null,
    lastName = null,
  } = user || {};

  const {
    uid = originalUID,
    admin,
    student,
    organizationCode = originalCode,
  } = metadata || {};

  const {
    state: {messageChain, messageMetadata},
    getAtRiskMessages,
    sendAtRiskMessage,
    setMessageMetadata,
  } = useContext(DashboardContext);

  const current = messageChain[uid] || [];
  const meta = messageMetadata[uid] || null;

  const [resource, setResource] = useState([]);
  const [message, setMessage] = useState('');
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const scrollRef = useRef(null);

  const visible = useOnScreen(ref);

  useEffect(() => {
    if (!current.length) {
      getAtRiskMessages(uid);
    }
  }, [uid]);

  useEffect(() => {
    const fetch = async () => {
      if (uid && !current.length) {
        setLoading(true);
        await getAtRiskMessages(uid);
        setLoading(false);
      }
    };
    if (uid && !metadata) {
      meta(uid);
    }
    fetch();
  }, [uid]);

  useEffect(() => {
    if (visible) {
      scrollRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [messageChain[uid], visible]);

  return (
    <Card justifyContent="center">
      <CardHeader
        title="Messenger"
        subheader="All communications are sent via the FinSiteful app as well as email."
      />
      <Divider />
      {index === 0 && (
        <Box>
          <Box sx={{pl: 4, pr: 4}}>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '350px',
                overflow: 'auto',
              }}>
              {current.map((message, i) => {
                const {body, senderUID, date} = message;
                const time = new Date(date);
                const prev = current[i - 1] || null;
                const prevTime = prev ? new Date(prev.date) : null;
                const diffDay =
                  i &&
                  prev &&
                  prevTime &&
                  dateToString(time) === dateToString(prevTime)
                    ? false
                    : true;

                return (
                  <Box
                    style={{
                      flex: 1,
                      maxWidth: '66%',
                      alignSelf: senderUID === uid ? 'flex-start' : 'flex-end',
                    }}>
                    {diffDay && (
                      <Typography
                        align={senderUID === uid ? 'right' : 'left'}
                        noWrap={false}
                        sx={{
                          fontSize: 14,
                          maxWidth: '66%',
                        }}>
                        {prettifyMediumDate(time)}
                      </Typography>
                    )}
                    <Card
                      style={{
                        backgroundColor:
                          senderUID === uid
                            ? palette.neutral['200']
                            : palette.info.light,
                        borderRadius: '16px',
                        alignSelf: 'flex-start',
                        marginTop: '4px',
                        marginBottom: '4px',
                        padding: 8,
                      }}>
                      <AutoLinkText text={body} />
                    </Card>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: senderUID === uid ? 'start' : 'end',
                        color: GlobalConstants.GRAY,
                      }}>
                      {prettifyTime(time)}
                    </Typography>
                  </Box>
                );
              })}
              <ListItem ref={scrollRef} />
            </List>
          </Box>
          <Divider />
          <Box
            ref={ref}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
              padding: '8px 8px',
              alignItems: 'flex-start',
            }}>
            <Button
              sx={{alignSelf: 'flex-start', marginBottom: '8px'}}
              onClick={() => {
                setIndex(1);
              }}>
              Attach a Resource
            </Button>
            <div
              style={{
                display: 'flex',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}>
              <TextField
                size="small"
                multiline
                value={message}
                onChange={evt => {
                  setMessage(evt.target.value);
                }}
                variant="outlined"
                id="message"
                label={`Send to ${student ? student.firstName : 'user'}...`}
                name="message"
                autoComplete="message"
                sx={{minWidth: '400px', marginTop: '8px'}}
              />
              <IconButton
                disabled={!message}
                sx={{
                  alignSelf: 'flex-end',
                  color: message
                    ? GlobalConstants.HIGHLIGHT
                    : GlobalConstants.GRAY,
                }}
                onClick={async () => {
                  let now = new Date().getTime();
                  const senderName = {
                    firstName: 'School',
                    lastName: 'Admin',
                  };
                  // SEND ALL RESOURCES
                  await Promise.all(
                    resource.map((res, i) => {
                      now += 1;
                      const data = {
                        body: res,
                        uid: v4(),
                        date: now,
                        senderUID: 'finsiteful',
                        userUID: uid,
                        organizationCode,
                        senderName,
                      };
                      return sendAtRiskMessage(uid, data);
                    }),
                  );
                  // SEND THE MESSAGE
                  now += 1;
                  const data = {
                    body: message,
                    uid: v4(),
                    date: now,
                    senderUID: 'finsiteful',
                    userUID: uid,
                    organizationCode,
                    senderName,
                  };
                  await sendAtRiskMessage(uid, data);
                  setMessage('');
                  setResource([]);
                  // UPDATE METADATA
                  const update = {
                    ...metadata,
                    admin: {lastSeen: now},
                    student:
                      metadata && metadata.student
                        ? metadata.student
                        : {
                            firstName,
                            lastName,
                            lastSeen: null,
                          },
                    lastMessage: {
                      body: message,
                      senderUID: 'finsiteful',
                      timestamp: now,
                      uid: data.uid,
                    },
                    lastUpdated: now,
                    organizationCode,
                    uid,
                  };
                  setMessageMetadata(uid, update);
                  // HANDLE ANY EXTRA SENDING
                  onSend && onSend();
                }}>
                <SendIcon />
              </IconButton>
            </div>
            {resource.length
              ? resource.map(res => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                      }}>
                      <Typography
                        sx={{flex: 1, fontSize: 14, marginRight: '8px'}}>
                        {res.title}
                      </Typography>
                      <CancelIcon
                        fontSize={'18px'}
                        onClick={() => {
                          const updated = resource.filter(item => {
                            return item.uid !== res.uid;
                          });
                          setResource(updated);
                          trackEvent('resourceRemoved', {resource: res});
                        }}
                      />
                    </div>
                  );
                })
              : null}
          </Box>
          {/* <PrimaryButton text="DONE" onClick={() => setIndex(0)} /> */}
        </Box>
      )}
      {index === 1 && (
        <Grid container>
          {resources.length && (
            <List sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
              {resources.map(resource => {
                return (
                  <ListItem
                    sx={{
                      width: '412px',
                    }}>
                    <div style={{flex: 1}}>
                      <ListItemText sx={{flex: 1}}>
                        {resource.title || 'N/A'}
                      </ListItemText>
                      <ListItemText>{resource.about || 'N/A'}</ListItemText>
                    </div>
                    <PrimaryButton
                      text="Choose"
                      onClick={() => {
                        setResource(prev => [...prev, resource]);
                        setIndex(0);
                        trackEvent('resourceAttached', {resource});
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default Messenger;
