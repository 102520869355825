import React, {useState, useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Common/Copyright';
import {useNavigate} from 'react-router-dom';
import schoolsArray from '../../assets/schools.json';
import {Context as AuthContext} from '../../contexts/AuthContext';
import logoImage from '../../assets/images/logoBlack.png';

const ComingSoon = ({showSuccess}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState();

  const {
    state: {error},
    signup,
  } = useContext(AuthContext);

  const processSignup = async () => {
    setLoading(true);

    const success = await signup(email, password, {
      firstName,
      lastName,
    });

    if (success) {
      showSuccess('Successfully signed up');
      navigate('/dashboard/atRisk');
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img
          alt="Finsiteful"
          className="logo-image"
          style={{width: '210px', height: '150px'}}
          src={logoImage}
        />
        <Typography component="h1" variant="h5">
          Web version coming soon!
        </Typography>
        <Box noValidate sx={{mt: 3}}>
          {/* <Grid container spacing={2}> */}
          {/* <Grid item xs={12} sm={6}>
              <TextField
                value={firstName}
                onChange={evt => {
                  setFirstName(evt.target.value);
                }}
                variant="outlined"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={lastName}
                onChange={evt => {
                  setLastName(evt.target.value);
                }}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={evt => {
                  setEmail(evt.target.value);
                }}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                onChange={evt => {
                  setPassword(evt.target.value);
                }}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            {error && <Typography>{error}</Typography>}
          </Grid>
          <Button
            onClick={processSignup}
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}>
            Sign Up
          </Button> */}
          {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{mt: 5}} />
    </Container>
  );
};

export default ComingSoon;
