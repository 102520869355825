import createDataContext from './createDataContext';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  collection,
  addDoc,
  query,
  setDoc,
  getDoc,
  doc,
  where,
} from 'firebase/firestore';
import {
  db,
  getAccounts,
  getClientProfile,
  getFinancialProfile,
  getTransactionsBetween,
  storage,
} from '../api/firestore';

const financialReducer = (state, action) => {
  switch (action.type) {
    case 'initial':
      return {...state, ...action.payload};
    case 'error':
      return {...state, error: action.payload};
    default:
      return state;
  }
};

const fetchInitial = dispatch => async uid => {
  try {
    const accounts = await getAccounts(uid);
    const transactions = await getTransactionsBetween(
      uid,
      '2022-09-01',
      '2022-09-31',
    );
    const payload = {accounts, transactions};
    dispatch({type: 'initial', payload});
  } catch (err) {
    handleErrors(err, dispatch);
  }
};

const defaultValues = {
  accounts: [],
  hiddenAccounts: [],
  liabilities: {credit: [], student: [], mortgage: []},
  rawLiabilities: [],
  institutions: [],
  transactions: [],
};

export const {Provider, Context} = createDataContext(
  financialReducer,
  {fetchInitial},
  defaultValues,
);

// TODO: handle fixing errors on success
const handleErrors = (err, dispatch) => {
  console.error(err);
  switch (err.code) {
    case 'auth/weak-password':
      dispatch({
        type: 'error',
        payload: 'Please enter a password that meets the requirements.',
      });
      break;
    case 'auth/invalid-email':
      dispatch({
        type: 'error',
        payload: 'You must provide a valid email.',
      });
      break;
    case 'auth/invalid-password':
      dispatch({
        type: 'error',
        payload: 'You must provide a valid password.',
      });
      break;
    case 'auth/email-already-in-use':
      dispatch({
        type: 'error',
        payload: 'That email is already in use.',
      });
      break;
    case 'auth/user-not-found':
      dispatch({
        type: 'error',
        payload: 'No user found with that email.',
      });
      break;
    case 'auth/wrong-password':
      dispatch({
        type: 'error',
        payload: 'The email or password is incorrect.',
      });
      break;
    case 'auth/multi-factor-auth-required':
      dispatch({
        type: 'error',
        payload: 'Requires multi-factor authentication',
      });
      break;
    case 'auth/missing-email':
      dispatch({
        type: 'error',
        payload: 'Missing or incorrect email.',
      });
      break;
    default:
      if (err.message === 'incomplete-fields') {
        dispatch({
          type: 'error',
          payload: 'Fill out all fields to continue.',
        });
      } else {
        dispatch({type: 'error', payload: 'Something went wrong.'});
      }
      break;
  }
};
