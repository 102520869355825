import React, {useState, useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import {IconButton, InputAdornment, OutlinedInput} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Common/Copyright';
import {useLocation, useNavigate} from 'react-router-dom';
import schoolsArray from '../../assets/schools.json';
import {Context as AuthContext} from '../../contexts/AuthContext';
import logoImage from '../../assets/images/horizontalLogo.png';
import authImage from '../../assets/images/authImage.png';
import Tile from '../../components/Common/Tile';
import GlobalConstants from '../../components/GlobalConstants';
import {PrimaryButton} from '../../components/Common/Buttons';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignUp = ({showSuccess}) => {
  const location = useLocation();
  const {search} = location;
  const code = search.slice(1);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(true);

  const {
    state: {error},
    signup,
  } = useContext(AuthContext);

  const processSignup = async () => {
    setLoading(true);

    const success = await signup(email, password, {
      firstName,
      lastName,
      code,
    });

    if (success) {
      showSuccess('Successfully signed up');
      navigate('/dashboard/atRisk');
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: GlobalConstants.BACKGROUND,
        height: '100vp',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div style={{marginRight: '64px'}}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <img
              alt="Finsiteful"
              className="logo-image"
              style={{width: '256px', height: '77px'}}
              src={logoImage}
            />
            <Typography
              component="h1"
              variant="h5"
              sx={{fontWeight: 'bold', margin: '16px'}}>
              Sign up
            </Typography>
            <Tile>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                  size="small"
                  value={firstName}
                  onChange={evt => {
                    setFirstName(evt.target.value);
                  }}
                  variant="outlined"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  margin="normal"
                  sx={{marginRight: '64px'}}
                />
                <TextField
                  size="small"
                  value={lastName}
                  onChange={evt => {
                    setLastName(evt.target.value);
                  }}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  margin="normal"
                />
              </div>
              <TextField
                size="small"
                value={email}
                onChange={evt => {
                  setEmail(evt.target.value);
                }}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Edu Email Address"
                name="email"
                autoComplete="email"
                margin="normal"
              />

              <TextField
                size="small"
                value={password}
                onChange={evt => {
                  setPassword(evt.target.value);
                }}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type={togglePassword ? 'password' : null}
                id="password"
                autoComplete="new-password"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setTogglePassword(!togglePassword)}
                        edge="end">
                        {togglePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                component="p2"
                variant="p6"
                sx={{
                  fontSize: 12,
                  marginHorizontal: '16px',
                  textAlign: 'center',
                  marginTop: '8px',
                }}>
                Must be 12 characters including a letter, number, and symbol
                (@$!%*#?&)
              </Typography>
              {error && (
                <Typography
                  sx={{color: GlobalConstants.RED, textAlign: 'center'}}>
                  {error}
                </Typography>
              )}

              <PrimaryButton
                text="Sign Up"
                onClick={processSignup}
                detailed
                icon
              />
            </Tile>
            <Typography sx={{margin: '16px'}}>
              Already have an account?{' '}
              <Link
                href="/login"
                variant="body2"
                color={GlobalConstants.HIGHLIGHT}>
                Log in
              </Link>
            </Typography>
          </Box>
        </div>
        <div style={{marginTop: '128px'}}>
          <img
            alt="Finsiteful"
            className="logo-image"
            style={{width: '384px', height: '328px'}}
            src={authImage}
          />
        </div>
      </div>

      <Copyright sx={{mt: 5}} />
    </div>
  );
};

export default SignUp;
