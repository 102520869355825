import {AnalyticsBrowser} from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load({
  writeKey: 'gzbeKIrVL6KFFagkWlnzwbyIHFEiGWJh',
});

const identifyUser = (uid, options) => {
  if (uid) {
    analytics.identify(uid, options);
  }
};

const trackEvent = (action, options) => {
  analytics.track(action, {...options});
};

const trackPage = name => {
  analytics.pageView(name);
};

export {identifyUser, trackEvent, trackPage};
