import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {DashboardNavbar} from './dashboard-navbar';
import {DashboardSidebar} from './dashboard-sidebar';
import {Box} from '@mui/material';
import {useAuth} from '../../hooks/useAuth';
import {useDashboard} from '../../hooks/useDashboard';
import {useIntercom} from 'react-use-intercom';

const DashboardLayoutRoot = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280,
  },
}));

export const DashboardLayout = props => {
  const {children} = props;
  const {boot, shutdown, hide, show, update} = useIntercom();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const {
    state: {uid, universal},
  } = useAuth();
  const {getProfiles, getAtRisk, getFakeData} = useDashboard();

  const {organizationCode, dashboard} = universal;

  useEffect(() => {
    const {uid, firstName, lastName, email, organizationCode, studentID} =
      universal;
    update({
      firstName,
      lastName,
      email,
      organizationCode,
      studentID,
      platform: 'dashboard',
    });
  }, []);

  useEffect(() => {
    if (!organizationCode || !dashboard) {
      return;
    }
    if (universal.dashboard.role === 'demo' && organizationCode === 'fake') {
      getFakeData();
    } else {
      getProfiles(organizationCode);
    }
  }, [organizationCode]);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}>
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
