import React, {useState, useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/Common/Copyright';
import {Context as AuthContext} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import logoImage from '../../assets/images/logoBlack.png';
import GlobalConstants from '../../components/GlobalConstants';

const ForgotPassword = ({showSuccess, showError}) => {
  const navigate = useNavigate();
  const loadedEmail = localStorage.getItem('email');
  const [email, setEmail] = useState(loadedEmail ? loadedEmail : '');
  const [loading, setLoading] = useState();

  const {
    state: {error},
    sendPasswordReset,
  } = useContext(AuthContext);

  const processLogin = async () => {
    setLoading(true);
    await sendPasswordReset(email);
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img
          alt="Finsiteful"
          className="logo-image"
          style={{width: '210px', height: '150px'}}
          src={logoImage}
        />
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>

        <Box
          noValidate
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <TextField
            value={email}
            onChange={evt => {
              setEmail(evt.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            sx={{minWidth: '300px'}}
          />

          {error && (
            <Typography component="p" variant="h5">
              {error}
            </Typography>
          )}
          <Button
            onClick={processLogin}
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}>
            Send Password Reset
          </Button>
          <Link href="/login" variant="body2" color={GlobalConstants.HIGHLIGHT}>
            Login
          </Link>
        </Box>
      </Box>
      <Copyright sx={{mt: 8, mb: 4}} />
    </Container>
  );
};

export default ForgotPassword;
