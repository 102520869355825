import React from 'react';
import {Paper} from '@mui/material';

const Tile = ({children, style}) => {
  return (
    <Paper
      sx={[
        {
          padding: '16px 32px',
          borderRadius: '12px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        },
        style,
      ]}
      elevation={24}>
      {children}
    </Paper>
  );
};

export default Tile;
