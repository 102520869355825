import React from 'react';
import {Button} from '@mui/material';
import GlobalConstants from '../GlobalConstants';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export const PrimaryButton = ({onClick, text, color, detailed, icon}) => {
  return (
    <Button
      onClick={onClick}
      // type="submit"
      // variant="contained"
      className={detailed ? 'button' : null}
      sx={{
        mb: 2,
        mt: 2,
        alignSelf: 'center',
        color: 'white',
        backgroundColor: color || GlobalConstants.HIGHLIGHT,
        borderRadius: 16,
        minWidth: '128px',
      }}
      endIcon={icon && <ChevronRightIcon />}>
      {text}
    </Button>
  );
};

export const SecondaryButton = ({text, onClick, style}) => {
  return (
    <Button
      onClick={onClick}
      // type="submit"
      variant="outlined"
      color={'highlight'}
      sx={[
        {
          mb: 2,
          mt: 2,
          alignSelf: 'center',
          borderRadius: 16,
          minWidth: '128px',
        },
        style,
      ]}>
      {text}
    </Button>
  );
};

export const BackButton = ({onClick}) => {
  return (
    <Button onClick={onClick} startIcon={<ChevronLeftIcon />}>
      Back
    </Button>
  );
};

export const LinkButton = ({text, onClick, style}) => {
  return (
    <button className="link-button" onClick={onClick} style={style}>
      {text}
    </button>
  );
};
