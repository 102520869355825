import {
  Button,
  Card,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {trackEvent} from '../../api/segment';
import {PrimaryButton} from '../../components/Common/Buttons';
import {useAuth} from '../../hooks/useAuth';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Box} from '@mui/system';

export const EditEmail = ({setShow}) => {
  const {
    state: {uid, auth, universal},
    updateEmail,
  } = useAuth();

  const {email: originalEmail = null} = universal;

  const [loading, setLoading] = useState(false);

  const reset = () => {
    setShow(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      oldEmail: originalEmail || '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      oldEmail: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      const {email, oldEmail, password} = values;
      const {success, error} = await updateEmail(email, oldEmail, password);

      if (success) {
        reset();
        trackEvent('emailUpdated', {uid, oldEmail, email});
      } else {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: error});
        helpers.setSubmitting(false);
      }

      setLoading(false);
    },
  });

  return (
    <Card sx={{p: 4}}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="New Email"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <TextField
          error={Boolean(formik.touched.oldEmail && formik.errors.oldEmail)}
          fullWidth
          helperText={formik.touched.oldEmail && formik.errors.oldEmail}
          label="Old Email"
          margin="normal"
          name="oldEmail"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.oldEmail}
        />
        <TextField
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
        />
        {formik.errors.submit && (
          <Box sx={{mt: 3}}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}
        <Box sx={{mt: 2}}>
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained">
            Save
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export const EditPassword = ({setShow}) => {
  const {
    state: {uid, auth, universal},
    updatePassword,
  } = useAuth();

  const {email: originalEmail = null} = universal;

  const [loading, setLoading] = useState(false);

  const reset = () => {
    setShow(false);
  };

  const formik = useFormik({
    initialValues: {
      email: originalEmail || '',
      oldPassword: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      oldPassword: Yup.string().max(255).required('Password is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      const {email, oldPassword, password} = values;
      const {success, error} = await updatePassword(
        email,
        password,
        oldPassword,
      );
      if (success) {
        reset();
        trackEvent('passwordUpdated', {uid});
      } else {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: error});
        helpers.setSubmitting(false);
      }

      setLoading(false);
    },
  });

  return (
    <Card sx={{p: 4}}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <TextField
          error={Boolean(
            formik.touched.oldPassword && formik.errors.oldPassword,
          )}
          fullWidth
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          label="Old Password"
          margin="normal"
          name="oldPassword"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.oldPassword}
        />
        <TextField
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="New Password"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
        />
        {formik.errors.submit && (
          <Box sx={{mt: 3}}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}
        <Box sx={{mt: 2}}>
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained">
            Save
          </Button>
        </Box>
      </form>
    </Card>
  );
};
