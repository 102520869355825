import React, {useState} from 'react';
import Link from '@mui/material/Link';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  IconButton,
  Tooltip,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Typography} from '@mui/material';
import DashboardTile from '../../components/Tiles/DashboardTile';
import {PrimaryButton} from '../../components/Common/Buttons';
import {setUserId} from 'firebase/analytics';
import GlobalConstants from '../../components/GlobalConstants';
import Paginator from '../../components/Common/Paginator';
import Spacer from '../../components/Common/Spacer';
import {trackEvent} from '../../api/segment';
import {InfoCircleIcon} from '../../assets/icons/info-circle';
import {useNavigate} from 'react-router-dom';

export const AtRiskInNeed = ({data, count, setSidebar, setUser}) => {
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);
  const [page, setPage] = useState(1);
  const pageLength = 10;
  const start = (page - 1) * pageLength;
  const end = page * pageLength;

  if (!data) {
    return null;
  }

  const sorted = data.sort((a, b) => {
    const aDate = new Date(a.atRiskDate).getTime();
    const bDate = new Date(b.atRiskDate).getTime();
    const val = bDate - aDate;
    return val;
  });

  const helpNeeded = sorted.filter(user => {
    const {shareAtRiskAccepted, actionTaken, atRiskResolved} = user;
    if (!shareAtRiskAccepted) {
      return false;
    }

    if (actionTaken) {
      return false;
    }
    if (atRiskResolved) {
      return false;
    }
    return true;
  });

  return (
    <div>
      <Badge
        badgeContent={helpNeeded.length}
        color={'error'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Card>
          <CardHeader
            title="At-Risk Students NEEDING HELP"
            subheader="Below are your students who are identified as being at-risk and who have requested help."
            action={
              <Tooltip
                title={
                  'List of at-risk students who have requested help. Click “Help Them” to send resources their way.'
                }
                sx={{mr: 2}}>
                <InfoCircleIcon />
              </Tooltip>
            }
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{}}>Start Date</TableCell>
                <TableCell sx={{}}>Name</TableCell>
                <TableCell sx={{}}>Email</TableCell>
                <TableCell sx={{}}>Student ID</TableCell>
                <TableCell sx={{}}>Issue</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {helpNeeded.slice(start, end).map(row => {
                const {
                  atRiskDate,
                  firstName,
                  lastName,
                  email,
                  studentID,
                  atRiskIssue,
                } = row;
                const access = row.shareAtRiskAccepted ? true : false;

                return (
                  <TableRow key={row.uid} sx={{}}>
                    <TableCell>{atRiskDate}</TableCell>
                    <TableCell>
                      {firstName && lastName
                        ? firstName + ' ' + lastName
                        : 'John Doe'}
                    </TableCell>
                    <TableCell>{email || 'jdoe@gmail.com'}</TableCell>
                    <TableCell>{studentID || 'N/A'}</TableCell>
                    <TableCell>{atRiskIssue || 'N/A'}</TableCell>
                    <TableCell>
                      <PrimaryButton
                        text={'Help them'}
                        color={GlobalConstants.RED}
                        onClick={() => {
                          navigate('/dashboard/atRisk/user', {
                            state: {user: row},
                          });
                          trackEvent('atRiskStudentViewed', {
                            user: row,
                            inProgress: false,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Paginator
            length={helpNeeded.length}
            pageLength={pageLength}
            page={page}
            setPage={setPage}
          />
        </Card>
      </Badge>
    </div>
  );
};

export const AtRiskInProgress = ({data, count, setSidebar, setUser}) => {
  const [seeMore, setSeeMore] = useState(false);
  const [page, setPage] = useState(1);
  const pageLength = 10;
  const start = (page - 1) * pageLength;
  const end = page * pageLength;

  if (!data) {
    return null;
  }

  const sorted = data.sort((a, b) => {
    const aDate = new Date(a.atRiskDate).getTime();
    const bDate = new Date(b.atRiskDate).getTime();
    const val = bDate - aDate;
    return val;
  });

  const helpNeeded = [];
  const active = sorted.filter(user => {
    const {shareAtRiskAccepted, actionTaken, atRiskResolved} = user;
    if (!shareAtRiskAccepted) {
      return false;
    }

    if (actionTaken) {
      return true;
    }
    if (atRiskResolved) {
      return false;
    }
    helpNeeded.push(user);
    return false;
  });

  return (
    <div>
      <Card>
        <CardHeader
          title="At-Risk Students IN PROGRESS"
          action={
            <Tooltip
              title={
                'List of at-risk students who have received help from you. Click “Check In” to follow up with them or mark them as helped.'
              }
              sx={{mr: 2}}>
              <InfoCircleIcon />
            </Tooltip>
          }
        />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{}}>Start Date</TableCell>
              <TableCell sx={{}}>Name</TableCell>
              <TableCell sx={{}}>Email</TableCell>
              <TableCell sx={{}}>Student ID</TableCell>
              <TableCell sx={{}}>Issue</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {active.slice(start, end).map(row => {
              const {
                atRiskDate,
                firstName,
                lastName,
                email,
                studentID,
                atRiskIssue,
              } = row;
              const access = row.shareAtRiskAccepted ? true : false;

              return (
                <TableRow key={row.uid} sx={{}}>
                  <TableCell>{atRiskDate}</TableCell>
                  <TableCell>
                    {firstName && lastName
                      ? firstName + ' ' + lastName
                      : 'John Doe'}
                  </TableCell>
                  <TableCell>{email || 'jdoe@gmail.com'}</TableCell>
                  <TableCell>{studentID || 'N/A'}</TableCell>
                  <TableCell>{atRiskIssue || 'N/A'}</TableCell>
                  <TableCell>
                    <PrimaryButton
                      text={'Check In'}
                      color={GlobalConstants.GREEN}
                      onClick={() => {
                        setSidebar(true);
                        setUser(row);
                        trackEvent('atRiskStudentViewed', {
                          user: row,
                          inProgress: true,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Paginator
          length={active.length}
          pageLength={pageLength}
          page={page}
          setPage={setPage}
        />
      </Card>
    </div>
  );
};
