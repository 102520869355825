/*
Date API
constructors to convert dates to new timezones or to new types
*/

// Sets the year month day relative to the current day
export const goBackInTimeFromToday = (year, month, day) => {
  const today = currentLocalTime();
  const newDate = new Date(
    today.getFullYear() - year,
    today.getMonth() - month,
    today.getDate() - day,
  );
  return newDate;
};
// Sets the year month day relative to the current day
export const goForwardInTimeFromToday = (year, month, day) => {
  const today = currentLocalTime();
  const newDate = new Date(
    today.getFullYear() + year,
    today.getMonth() + month,
    today.getDate() + day,
  );
  return newDate;
};

// Sets the year month day relative to the current day
export const goForwardInTimeFromDate = (date, year, month, day) => {
  const newDate = new Date(
    date.getFullYear() + year,
    date.getMonth() + month,
    date.getDate() + day,
  );
  return newDate;
};

export const convertToLocalTime = (date = new Date()) => {
  const minuteDiff = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - minuteDiff);
  return date;
};

// Returns the current local time
export const currentLocalTime = () => {
  var local = new Date();
  const minuteDiff = local.getTimezoneOffset();
  local.setMinutes(local.getMinutes() - minuteDiff);
  return local;
};

// Returns the current local time as a string in the form "YYYY-MM-DD"
export const currentLocalTimeToString = () => {
  const today = currentLocalTime();
  return dateToString(today);
};

// Finds the number of days left in the current month
export const daysLeftInMonth = () => {
  const date = currentLocalTime();
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return endOfMonth.getDate() - date.getDate();
};

// Finds the number of days left in the current month
export const endOfMonth = (date = new Date()) => {
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return endOfMonth;
};

// Finds the date, day of the month
export const dayOfMonth = (date = new Date()) => {
  return date.getDate();
};

// Finds the date, day of the week
export const dayOfWeek = (date = new Date()) => {
  return date.getDay();
};

// Finds the number of days left in the current Year
export const monthsLeftInYear = () => {
  const date = currentLocalTime();
  // 11 since index starts at 0
  return 11 - date.getMonth();
};

// Converts the current date to the first of the week
export const firstOfWeek = () => {
  const today = currentLocalTime();
  const days = today.getDay();
  return goBackInTimeFromToday(0, 0, days);
};

// Converts the current date to the first of the week string
export const firstOfWeekToString = () => {
  const today = currentLocalTime();
  const days = today.getDay();
  const sunday = goBackInTimeFromToday(0, 0, days);
  return dateToString(sunday);
};

// Converts the current date to the first of the week string
export const getLastSundayAndNextSaturdayDates = () => {
  const today = new Date();
  const days = today.getDay();
  const lastSunday = goBackInTimeFromToday(0, 0, days);
  const nextSaturday = goForwardInTimeFromToday(0, 0, days - (5 - days));
  return {lastSunday, nextSaturday};
};

// Converts a date to into a string of the form "YYYY-MM-DD"
export const dateToString = (date = new Date()) => {
  return date.toISOString().slice(0, 10);
};

export const dateToMS = (date = new Date()) => {
  return date.getTime();
};

// Returns the current year and month in the form "YYYY-MM"
export const currentYearMonthToString = () => {
  const date = currentLocalTime();
  const dateString = dateToString(date);
  return dateString.slice(0, 7);
};

// Returns the passed in date year and month in the form "YYYY-MM"
export const yearMonthToString = (date = new Date()) => {
  const dateString = dateToString(date);
  return dateString.slice(0, 7);
};

// Returns the  year in the form "YYYY"
export const yearToString = (date = new Date()) => {
  const dateString = dateToString(date);
  return dateString.slice(0, 4);
};

// Returns the current year in the form "YYYY"
export const currentYearString = () => {
  const date = currentLocalTime();
  const dateString = dateToString(date);
  return dateString.slice(0, 4);
};

// Returns the current first of month as a string
export const firstOfMonth = (date = currentLocalTime()) => {
  const first = new Date(date.getFullYear(), date.getMonth(), 1);
  return first;
};

// Returns the current last of month as a string
export const lastOfMonth = (date = currentLocalTime()) => {
  const last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return last;
};

// Returns the current first of month as a string
export const firstOfMonthString = (date = currentLocalTime()) => {
  const first = new Date(date.getFullYear(), date.getMonth(), 1);
  return dateToString(first);
};

// Returns the current last of month as a string
export const lastOfMonthString = (date = currentLocalTime()) => {
  const last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return dateToString(last);
};

// finds the difference in months between the start and end date
export const monthDiff = (startDate, endDate = new Date()) => {
  const diff =
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear());
  return diff + 1;
};

// finds the difference in days between the start and end date
export const dayDiff = (startDate, endDate = new Date()) => {
  const diff = Math.abs(endDate - startDate);
  return Math.round(diff / (1000 * 60 * 60 * 24));
};

// finds the difference in hours between the start and end date
export const hourDiff = (startDate, endDate = new Date()) => {
  const diff = Math.abs(endDate - startDate);
  return diff / (1000 * 60 * 60);
};

// finds the difference in minutes between the start and end date
export const minDiff = (startDate, endDate = new Date()) => {
  const diff = Math.abs(endDate - startDate);
  return diff / 1000 / 60;
};

export const prettifyTime = date => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridium = hours > 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const decimalMinutes = minutes > 10 ? minutes : '0' + minutes;
  return hours12 + ':' + decimalMinutes + ' ' + meridium;
};

// prints date as a string in the form Mon Jul 19 2021 09:35
export const prettifyFullDate = date => {
  let stringify = date.toString();
  stringify = stringify.slice(0, 21);
  return stringify;
};

// prints the date as a string in the form Oct 4, 2021
export const prettifyMediumDate = date => {
  try {
    let stringify = date.toString();
    let split = stringify.split(' ');
    const prettify = split[1] + ' ' + split[2] + ', ' + split[3];
    return prettify;
  } catch (err) {
    return '';
  }
};

// prints the date as a string in the form dd/mm/yyyy
export const prettifyShortDate = date => {
  const stringify = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;
  return stringify;
};

// prints the date as a string in the form dd/mm/yyyy
export const prettifyMonthDay = date => {
  const stringify = `${date.getMonth() + 1}/${date.getDate()}`;
  return stringify;
};

// Dec 31
export const prettifyShortFullDate = (date = new Date()) => {
  try {
    date = convertToDate(date);
    let stringify = date.toString();
    let split = stringify.split(' ');
    const prettify = split[1] + ' ' + split[2];
    return prettify;
  } catch (err) {
    return '';
  }
};

export const prettifyMonthYear = (date = new Date()) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[date.getMonth()];
  return month + ' ' + date.getFullYear();
};

export const prettifyMonthYearShort = (date = new Date()) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = months[date.getMonth()];
  return month + ' ' + date.getFullYear();
};

export const prettifyMonth = (date = new Date()) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[date.getMonth()];
};

export const prettifyMonthShort = (date = new Date()) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[date.getMonth()];
};

// finds the number of days in the given month
export const daysInThisMonth = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

// obj can be a FIRTimestamp or a Date object
export function convertToDate(obj) {
  if (!obj) {
    return;
  }
  const type = typeof obj;
  switch (type) {
    case 'object':
      // TODO: why is toDate not working???
      // return obj instanceof Date ? obj : obj.toDate();
      return obj instanceof Date ? obj : new Date(obj.seconds * 1000);
    case 'string':
      return new Date(obj);
    default:
      return obj;
  }
}

// creates a new date from a string
export const newDate = string => {
  return new Date(string + 'T12:00:00.000Z');
};

//find the most recent monday from the most recent sunday
export const lastMondayDate = () => {
  var lastMonday = currentLocalTime();
  lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() + 1);
  return lastMonday;
};

// finds the time since posting
export const findTime = dateInput => {
  let date = dateInput;
  if (dateInput && dateInput.seconds) {
    date = new Date(dateInput.seconds * 1000);
  }
  try {
    if (minDiff(date) < 60) {
      return `${Math.ceil(minDiff(date))}m`;
    }
    if (hourDiff(date) < 24) {
      return `${Math.ceil(hourDiff(date))}h`;
    }
    return `${Math.ceil(dayDiff(date))}d`;
  } catch (err) {}
  return hourDiff(date) < 24
    ? `${Math.ceil(hourDiff(date))}h`
    : `${Math.ceil(dayDiff(date))}d`;
};
