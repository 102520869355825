/* eslint-disable import/no-anonymous-default-export */
// To consistently format numbers
// toUSD -> turns a number into a dollar amount of the form $xx.xx
export default () => {
  const toUSD = (number = 0) => {
    // convert to number if not already
    if (typeof number === 'string') {
      number = Number(number);
    }
    const nf = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const formatted = nf.format(number);
    return formatted;
  };

  const toShortUSD = (number = 0) => {
    // convert to number if not already
    if (typeof number === 'string') {
      number = Number(number);
    }
    const nf = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const million = 1000000;
    const thousand = 1000;
    if (number >= million) {
      const shortNumber = number / million;
      const shortFormatted = nf.format(shortNumber.toFixed(2));
      return shortFormatted + 'M';
    } else if (number >= thousand) {
      const shortNumber = number / thousand;
      const shortFormatted = nf.format(shortNumber.toFixed(2));
      return shortFormatted + 'K';
    } else {
      const formatted = nf.format(number);
      return formatted;
    }
  };

  const toNumber = number => {
    if (isNaN(number)) {
      return 0;
    }
    const casted = Number(number);
    return casted;
  };

  const getRandomInt = max => {
    return Math.floor(Math.random() * max);
  };

  return [toUSD, toShortUSD, toNumber, getRandomInt];
};
