import React, {useState, useContext} from 'react';
import {
  Box,
  Toolbar,
  Typography,
  Container,
  Paper,
  Grid,
  TextField,
  Card,
  Divider,
  CardActions,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import DashboardTile from '../../components/Tiles/DashboardTile';
import PieChartTile from '../../components/Charts/PieChartTile';
import LineChartTile from '../../components/Charts/LineChartTile';
import Copyright from '../../components/Common/Copyright';
import {Context as DashboardContext} from '../../contexts/DashboardContext';
import {InfoCircleIcon} from '../../assets/icons/info-circle';
import {SimpleBox} from '../../components/Layout/cards';

const toReadable = key => {
  switch (key) {
    case 'creditModule':
      return 'Credit Guide';
    case 'investingModule':
      return 'Investing Guide';
    case 'savingsModule':
      return 'Savings Guide';
    case 'lowCostActivitiesModule':
      return 'Low Cost Activities';
    case 'collegeHacksModule':
      return 'College Hacks';
    case 'jobsForStudentsModule':
      return 'Jobs for Students';
    case 'costOfLivingModule':
      return 'Cost of Living';
    case 'nilModule':
      return 'NIL';
    case 'rentingApartmentModule':
      return 'Renting Apartment';
    case 'retirementAccountsModule':
      return 'Retirement Accounts';
    case 'fafsaModule':
      return 'FAFSA';
    case 'taxesModule':
      return 'Taxes for Employees';
    case 'contractorTaxesModule':
      return 'Taxes for Contractor';
    case 'taxCreditModule':
      return 'Tax Credit';
    case 'creditScoreModule':
      return 'Credit Score';
    case 'whatToInvestInModule':
      return 'What to Invest In';
    case 'emergencyFundModule':
      return 'Emergency Fund';
    case 'managingIncomeModule':
      return 'Managing Income';
    case 'studentLoansModule':
      return 'Student Loans';
    default:
      return key;
  }
};

const UsageDashboard = ({}) => {
  const {state, getProfiles, getAtRisk} = useContext(DashboardContext);

  const {
    users,
    userCount,
    atRisk,
    askHelp,
    genderBreakdown,
    employmentBreakdown,
    majorBreakdown,
    birthYearBreakdown,
    signUpsSummation,
    appProgress,
    moduleInteraction,
    deleted,
  } = state;

  const moduleArray = Object.keys(moduleInteraction)
    .map(key => {
      return {name: toReadable(key), value: moduleInteraction[key]};
    })
    .sort((a, b) => a.value < b.value);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="lg">
          <Box sx={{mb: 4}}>
            <Typography variant="h4">Usage Dashboard</Typography>
          </Box>
          <Grid container spacing={4}>
            <SimpleBox
              header="Sign Ups"
              info="See how many of your students are currently signed up with FinSiteful."
              data={users.length || userCount}
            />
            <SimpleBox
              header="Asked for Help"
              info="See how many of your students have asked for help."
              data={askHelp}
            />
            <SimpleBox
              header="At-Risk"
              info="See how many of your students have been identified as at risk of dropping out."
              data={atRisk}
            />
            {/* STATUS IN APP */}
            <Grid item xs={12} md={6} lg={6}>
              <PieChartTile
                header="App Setup Progress"
                data={[
                  {name: 'Not Onboarded', value: appProgress.notOnboarded},
                  {
                    name: 'Financial Profile Incomplete',
                    value: appProgress.incomplete,
                  },
                  {
                    name: 'Complete and Active',
                    value: appProgress.completeActive,
                  },
                  {
                    name: 'Complete and Inactive',
                    value: appProgress.completeInactive,
                  },
                ]}
                slice={8}
                height={'230px'}
              />
            </Grid>
            {/* MAJORS */}
            <Grid item xs={12} md={6} lg={6}>
              <PieChartTile
                header="Majors"
                info="See a high level breakdown of popular majors."
                data={majorBreakdown}
                slice={8}
                height={'352px'}
              />
            </Grid>
            {/* EMPLOYMENT */}
            <Grid item xs={12} md={6} lg={6}>
              <PieChartTile
                header="Employment Status"
                info="Breakdown of your students based on their employment status."
                data={employmentBreakdown}
                slice={8}
                height={'230px'}
              />
            </Grid>
            {/* GENDER */}
            <Grid item xs={12} md={6} lg={6}>
              <PieChartTile
                header="Gender"
                data={genderBreakdown}
                slice={8}
                height={'230px'}
              />
            </Grid>
            {/* MODULES */}
            <Grid item xs={12} md={6} lg={6}>
              <PieChartTile
                header="Financial Guides Interactions"
                info="See what information guides users are reading."
                data={moduleArray}
                slice={8}
                height={'230px'}
              />
            </Grid>
            {/* SIGN UPS SUM */}
            <Grid item xs={12} md={12} lg={12}>
              <LineChartTile
                header="Sign Ups By Day"
                info="See how many of your students are currently signed up with FinSiteful."
                data={signUpsSummation}
              />
            </Grid>
          </Grid>
        </Container>
        <Copyright sx={{pt: 4}} />
      </Box>
    </>
  );
};

export default UsageDashboard;
