import React, {useState, useContext} from 'react';
import {
  Box,
  Toolbar,
  Typography,
  Grid,
  Container,
  Paper,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Tooltip,
} from '@mui/material';
import DashboardTile from '../../components/Tiles/DashboardTile';
import Copyright from '../../components/Common/Copyright';
import PillarTile from '../../components/Tiles/PillarTile';
import {Line} from 'recharts';
import LineChartTile from '../../components/Charts/LineChartTile';
import GlobalConstants from '../../components/GlobalConstants';
import {Context as DashboardContext} from '../../contexts/DashboardContext';
import Tile from '../../components/Common/Tile';
import Header from '../../components/Common/Header';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import {useTheme} from '@emotion/react';
import {InfoCircleIcon} from '../../assets/icons/info-circle';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'purple', '#FF5722', 'orange'];

const FinancialDashboard = ({}) => {
  const [toUSD] = useNumberFormatter();

  const {state} = useContext(DashboardContext);
  const {
    raffleWinnings,
    pillarBreakdown,
    transactionCategoriesPerMonth,
    transactionTypesPerMonth,
    averageNetWorth,
    potentialSavings,
    appProgress,
  } = state;

  const lastMonth = potentialSavings.find(month => (month.date = '2020-04'));

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth="lg">
          <Box sx={{mb: 4}}>
            <Typography variant="h4">Financial Dashboard</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <AccountBreakdown
                averageNetWorth={averageNetWorth}
                pillarBreakdown={pillarBreakdown}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <LineChartTile
                header="Transaction Category Breakdown by Month"
                info="Where do your students spend the most money? Transactions are categorized automatically, but students can modify them."
                data={transactionCategoriesPerMonth}
                legend={true}>
                <Line
                  type="monotone"
                  dataKey="Transportation"
                  stroke={COLORS[0]}
                />
                <Line type="monotone" dataKey="Food" stroke={COLORS[1]} />
                <Line
                  type="monotone"
                  dataKey="Living Expenses"
                  stroke={COLORS[2]}
                />
              </LineChartTile>
            </Grid>

            {/* TRANSACTIONS BY MONTH */}
            <Grid item xs={12} md={12} lg={12}>
              <LineChartTile
                header="Transaction Types Per Month"
                data={transactionTypesPerMonth}
                legend={true}>
                <Line type="monotone" dataKey="spending" stroke={COLORS[0]} />
                <Line type="monotone" dataKey="income" stroke={COLORS[1]} />
                <Line type="monotone" dataKey="savings" stroke={COLORS[2]} />
                <Line type="monotone" dataKey="investment" stroke={COLORS[3]} />
              </LineChartTile>
            </Grid>
          </Grid>
          <Copyright sx={{pt: 4}} />
        </Container>
      </Box>
    </>
  );
};

export const AccountBreakdown = props => {
  const {pillarBreakdown, averageNetWorth} = props;
  const [toUSD] = useNumberFormatter();
  delete pillarBreakdown[undefined];
  const keys = Object.keys(pillarBreakdown || {});

  return (
    <Card {...props}>
      <CardHeader
        title="Average Net Worth"
        action={
          <Tooltip
            title={
              'Net worth is calculated using the below formula: Checking Balance + Savings Balance + Investments - Credit Card Debt = Net Worth'
            }
            sx={{mr: 2}}>
            <InfoCircleIcon />
          </Tooltip>
        }
      />
      <Divider />
      <CardContent sx={{fontSize: 34, fontWeight: 'bold', textAlign: 'center'}}>
        {toUSD(averageNetWorth)}
      </CardContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account Type</TableCell>
            <TableCell align="right">Average Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key, i) => {
            const data = pillarBreakdown[key];
            return (
              <TableRow key={key}>
                <TableCell>
                  <Box
                    key={key}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <Box
                      sx={{
                        border: 3,
                        borderColor: GlobalConstants.colors[i],
                        borderRadius: '50%',
                        height: 16,
                        mr: 1,
                        width: 16,
                      }}
                    />
                    <Typography variant="subtitle2">{key}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textSecondary" variant="body2">
                    {toUSD(data.average)}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

const NetWorth = ({averageNetWorth, pillarBreakdown}) => {
  const [toUSD] = useNumberFormatter();

  return (
    <Tile style={{height: 320}}>
      <Header
        header="Average Net Worth"
        subheader="The average net worth of your students."
      />
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: 32,
          marginBottom: '16px',
        }}>
        {toUSD(averageNetWorth)}
      </Typography>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1, textDecorationLine: 'underline'}}>
          Type of Account
        </Typography>
        <Typography sx={{textDecorationLine: 'underline'}}>
          Average Balance
        </Typography>
      </div>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1}}>Checking</Typography>
        <Typography sx={{}}>
          {toUSD(pillarBreakdown.checking.average || 0)}
        </Typography>
      </div>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1}}>Saving</Typography>
        <Typography sx={{}}>
          {toUSD(pillarBreakdown.savings.average || 0)}
        </Typography>
      </div>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1}}>Investment</Typography>
        <Typography sx={{}}>
          {toUSD(pillarBreakdown.investment.average || 0)}
        </Typography>
      </div>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1}}>Credit</Typography>
        <Typography sx={{}}>
          {toUSD(pillarBreakdown.credit.average || 0)}
        </Typography>
      </div>
      <div style={{display: 'flex', width: '100%'}}>
        <Typography sx={{flex: 1}}>Debt</Typography>
        <Typography sx={{}}>
          {toUSD(pillarBreakdown.debt.average || 0)}
        </Typography>
      </div>
    </Tile>
  );
};

export default FinancialDashboard;
