/* eslint-disable import/no-anonymous-default-export */
import React, {useEffect, useState, useContext} from 'react';
import './App.css';
import Login from './routes/Auth/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import {getAuth} from 'firebase/auth';
import {
  Provider as AuthProvider,
  Context as AuthContext,
} from './contexts/AuthContext';
import {Provider as DashboardProvider} from './contexts/DashboardContext';
import {
  Provider as FinancialProvider,
  Context as FinancialContext,
} from './contexts/FinancialContext';
import SignUp from './routes/Auth/Signup';
import ForgotPassword from './routes/Auth/ForgotPassword';
import {ThemeProvider} from '@mui/material/styles';
import DashboardHome from './routes/Dashboard/DashboardHome';
import PFMHome from './routes/FinancialManager/PFMHome';
import SettingsMenu from './routes/Settings/SettingsMenu';
import Account from './routes/Settings/AccountMenu';
import ComingSoon from './routes/Auth/ComingSoon';
import GlobalConstants from './components/GlobalConstants';
import MessagesHub from './routes/Messenger/MessagesHub';
import {IntercomProvider, useIntercom} from 'react-use-intercom';
import {trackPage} from './api/segment';
import lightTheme from './theme/lightTheme';
import {createTheme} from './theme';
import {DashboardLayout} from './components/Layout/dashboard-layout';
import {CssBaseline} from '@mui/material';
import FinancialDashboard from './routes/Dashboard/FinancialDashboard';
import UsageDashboard from './routes/Dashboard/UsageDashboard';
import AtRiskDashboard from './routes/Dashboard/AtRiskDashboard';
import {isMobile} from 'react-device-detect';
import UserDetail from './routes/Dashboard/UserDetail';
import {useAuth} from './hooks/useAuth';

function App() {
  const location = useLocation();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [success, setSuccess] = useState('');
  const [hasFetchedUserAuthState, setHasFetchedUserAuthState] = useState(false);
  const [userAuthState, setUserAuthState] = useState();

  const {
    state: {uid, client, authUser, universal},
    updateAuth,
  } = useAuth();

  const {boot, shutdown, hide, show, update} = useIntercom();

  boot();
  const triggerSuccess = msg => {
    setShowSuccess(true);
    setSuccess(msg);
  };

  const triggerError = msg => {
    setShowError(true);
    setError(msg);
  };

  const handleErrorClose = () => {
    setShowError(false);
    setError('');
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
    setSuccess('');
  };

  const RequireAuth = ({children, redirectTo}) => {
    if (userAuthState === undefined) {
      return <></>;
    }
    // IF AUTHENTICATED AND USER OBJECT
    if (userAuthState) {
      return children;
    }

    // IF NO AUTH
    return <Navigate to={redirectTo} />;
  };

  React.useEffect(() => {
    if (!hasFetchedUserAuthState) {
      const auth = getAuth();
      auth.onAuthStateChanged(user => {
        if (user) {
          setHasFetchedUserAuthState(true);
          setUserAuthState(user);
          updateAuth(user);
          // User is signed in.
        } else {
          // No user is signed in.
          setHasFetchedUserAuthState(true);
          setUserAuthState(false);
        }
      });
    }
  });

  useEffect(() => {
    const {pathname} = location;
    trackPage(pathname);
  }, [location]);

  if (isMobile) {
    return <div>This content is available only on desktop.</div>;
  }

  return (
    <>
      <Routes>
        <Route
          path="/signup"
          element={
            <SignUp showSuccess={triggerSuccess} showError={triggerError} />
          }
        />
        <Route
          path="/login"
          element={
            <Login showSuccess={triggerSuccess} showError={triggerError} />
          }
        />
        <Route
          path="/forgotPassword"
          element={
            <ForgotPassword
              showSuccess={triggerSuccess}
              showError={triggerError}
            />
          }
        />
        <Route
          path="/comingSoon"
          element={
            <RequireAuth redirectTo="/login">
              <ComingSoon />
            </RequireAuth>
          }
        />
        <Route
          path="/messenger"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <MessagesHub />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/atRisk"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <AtRiskDashboard />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/financial"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <FinancialDashboard />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/usage"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <UsageDashboard />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        {/* <Route
          path="/pfm"
          element={
            <RequireAuth redirectTo="/login">
              <PFMHome  />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/account"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <Account />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/atRisk/user"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <UserDetail />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/messenger/user"
          element={
            <RequireAuth redirectTo="/login">
              <DashboardLayout>
                <UserDetail />
              </DashboardLayout>
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <Login showSuccess={triggerSuccess} showError={triggerError} />
          }
        />
      </Routes>
    </>
  );
}

export default () => {
  const INTERCOM_APP_ID = 'ww60whap';
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <FinancialProvider>
        <DashboardProvider>
          <AuthProvider>
            <ThemeProvider
              theme={createTheme({
                direction: 'ltr',
                responsiveFontSizes: true,
                theme: 'light',
                mode: 'light',
              })}>
              <Router>
                <CssBaseline enableColorScheme />
                <App />
              </Router>
            </ThemeProvider>
          </AuthProvider>
        </DashboardProvider>
      </FinancialProvider>
    </IntercomProvider>
  );
};
