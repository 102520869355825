import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../Common/Title';
import {CustomBarChart} from './Charts';
import Link from '@mui/material/Link';
// import styles from '../../styles.css';
import {Bar, Cell, LabelList} from 'recharts';
import Header from '../Common/Header';
import {Box, Card, CardHeader, Divider, Tooltip} from '@mui/material';
import {InfoCircleIcon} from '../../assets/icons/info-circle';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'purple', '#FF5722', 'orange'];

function BarChartTile({header, subheader, data, info}) {
  const boxShadowStyle = {
    boxShadow:
      '0 22px 25px 0 rgb(228 228 234 / 22%), 0 9px 23px 0 rgb(228 228 234 / 50%)',
    '&:hover': {
      boxShadow:
        '0 22px 32px 0 rgb(228 228 234 / 56%), 0 9px 28px 0 rgb(228 228 234 / 64%)',
    },
  };

  const renderCustomizedLabel = props => {
    const {x, y, width, height, value} = props;

    return (
      <text
        x={x + width + 10}
        y={y + height / 2}
        fill="gray"
        dominantBaseline="middle">
        {value}
      </text>
    );
  };

  return (
    <Card sx={{}}>
      <CardHeader
        title={header}
        subheader={subheader}
        action={
          info && (
            <Tooltip title={info} sx={{mr: 2}}>
              <InfoCircleIcon />
            </Tooltip>
          )
        }
      />
      <Divider />
      <Box
        sx={{
          m: 4,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <CustomBarChart data={data}>
          <Bar dataKey="value">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <LabelList
              dataKey="name"
              position="right"
              fill={'gray'}
              content={renderCustomizedLabel}
            />
          </Bar>
        </CustomBarChart>
      </Box>
    </Card>
  );
}

export default BarChartTile;
