import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from '../Common/Title';
import {CustomLineChart} from './Charts';
import {Card, CardHeader, Divider, Tooltip, Box} from '@mui/material';
import {InfoCircleIcon} from '../../assets/icons/info-circle';

const LineChartTile = ({header, info, data, children, legend}) => {
  return (
    <Card sx={{}}>
      <CardHeader
        title={header}
        action={
          info && (
            <Tooltip title={info} sx={{mr: 2}}>
              <InfoCircleIcon />
            </Tooltip>
          )
        }
      />
      <Divider />
      <Box
        sx={{
          mt: 4,
          mb: 4,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <CustomLineChart data={data} legend={legend}>
          {children}
        </CustomLineChart>
      </Box>
    </Card>
  );
};

export default LineChartTile;
