import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {format, subDays, subHours} from 'date-fns';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import {ChatIcon} from '../../assets/icons/chat';
import {MailOpenIcon} from '../../assets/icons/mail-open';
import {XIcon} from '../../assets/icons/x';
import {UserCircleIcon} from '../../assets/icons/user-circle';
import {Scrollbar} from './scrollbar';

const now = new Date();

const data = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    author: 'Jie Yang Song',
    avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
    createdAt: new Date('2023-01-05').getTime(),
    job: '',
    read: false,
    action: 'John Doe uploaded a new dataset for Project 1 on X Date.',
    type: 'standard',
  },
  {
    id: 'bfb21a370c017acc416757c7',
    author: 'Jie Yang Song',
    avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
    createdAt: new Date('2022-12-29').getTime(),
    job: '',
    read: false,
    type: 'standard',
    action: 'New At-Risk Model Live!',
  },
  {
    id: '5e8883fca0e8612044248ecf',
    author: 'Jie Yang Song',
    avatar: '/static/mock-images/avatars/avatar-jie_yan_song.png',
    createdAt: new Date('2022-12-22').getTime(),
    read: false,
    type: 'standard',
    action:
      'Dataset in Project 2 is 30 days old. Please upload the newest version.',
  },
];

const getNotificationContent = notification => {
  const {type, action} = notification;
  switch (type) {
    case 'standard':
      return (
        <>
          <ListItemAvatar sx={{mt: 0.5}}>
            <Avatar>
              <ChatIcon fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}>
                <Typography variant="subtitle2" sx={{mr: 0.5}}>
                  {action}
                </Typography>
                <Typography variant="body2">
                  {notification.description}
                </Typography>
              </Box>
            }
            secondary={
              <Typography color="textSecondary" variant="caption">
                {format(notification.createdAt, 'MMM dd, h:mm a')}
              </Typography>
            }
            sx={{my: 0}}
          />
        </>
      );
    default:
      return null;
  }
};

export const NotificationsPopover = props => {
  const {anchorEl, onClose, onUpdateUnread, open, ...other} = props;
  const [notifications, setNotifications] = useState(data);
  const unread = useMemo(
    () =>
      notifications.reduce(
        (acc, notification) => acc + (notification.read ? 0 : 1),
        0,
      ),
    [notifications],
  );

  useEffect(() => {
    onUpdateUnread?.(unread);
  }, [onUpdateUnread, unread]);

  const handleMarkAllAsRead = () => {
    setNotifications(prevState =>
      prevState.map(notification => ({
        ...notification,
        read: true,
      })),
    );
  };

  const handleRemoveOne = notificationId => {
    setNotifications(prevState =>
      prevState.filter(notification => notification.id !== notificationId),
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={!!open}
      PaperProps={{sx: {width: 380}}}
      transitionDuration={0}
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}>
        <Typography color="inherit" variant="h6">
          Notifications
        </Typography>
        <Tooltip title="Mark all as read">
          <IconButton
            onClick={handleMarkAllAsRead}
            size="small"
            sx={{color: 'inherit'}}>
            <MailOpenIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      {notifications.length === 0 ? (
        <Box sx={{p: 2}}>
          <Typography variant="subtitle2">
            There are no notifications
          </Typography>
        </Box>
      ) : (
        <Scrollbar sx={{maxHeight: 400}}>
          <List disablePadding>
            {notifications.map(notification => (
              <ListItem
                divider
                key={notification.id}
                sx={{
                  alignItems: 'flex-start',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  '& .MuiListItemSecondaryAction-root': {
                    top: '24%',
                  },
                }}
                secondaryAction={
                  <Tooltip title="Remove">
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveOne(notification.id)}
                      size="small">
                      <XIcon sx={{fontSize: 14}} />
                    </IconButton>
                  </Tooltip>
                }>
                {getNotificationContent(notification)}
              </ListItem>
            ))}
          </List>
        </Scrollbar>
      )}
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  onUpdateUnread: PropTypes.func,
  open: PropTypes.bool,
};
