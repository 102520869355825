import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  Link,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth} from '../../hooks/useAuth';
import {CogIcon} from '../../assets/icons/cog';
import {UserCircleIcon} from '../../assets/icons/user-circle';
import {SwitchHorizontalOutlinedIcon} from '../../assets/icons/switch-horizontal-outlined';
import {useNavigate} from 'react-router-dom';

export const AccountPopover = props => {
  const navigate = useNavigate();
  const {anchorEl, onClose, open, ...other} = props;
  const {logout} = useAuth();
  // To get the user from the authContext, you can use
  const {
    state: {universal},
  } = useAuth();

  const handleLogout = async () => {
    try {
      onClose?.();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{sx: {width: 300}}}
      transitionDuration={0}
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}>
        {/* <Avatar
          src={
            universal.avatar || '/static/mock-images/avatars/avatar-anika_visser.png'
          }
          sx={{
            height: 40,
            width: 40,
          }}>
          <UserCircleIcon fontSize="small" />
        </Avatar> */}
        <Box
          sx={{
            ml: 1,
          }}>
          <Typography variant="body1">
            {universal.firstName + ' ' + universal.lastName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            FinSiteful
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{my: 1}}>
        <Link
          onClick={() => {
            onClose?.();
            navigate('/account');
          }}>
          <MenuItem component="a">
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Profile</Typography>}
            />
          </MenuItem>
        </Link>
        {/* <Link
          onClick={() => {
            onClose?.();
            navigate('/account');
          }}>
          <MenuItem component="a">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Settings</Typography>}
            />
          </MenuItem>
        </Link> */}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Logout</Typography>}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
