import React from 'react';
import {Box, IconButton, Pagination, Typography} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {trackEvent} from '../../api/segment';

const Paginator = ({length, pageLength, page, setPage}) => {
  return (
    <Pagination
      sx={{m: 2}}
      count={Math.ceil(length / pageLength) || 1}
      page={page}
      variant="outlined"
      shape="rounded"
      size="small"
      onChange={(evt, page) => {
        setPage(page);
      }}
    />
  );
};

export default Paginator;
