import {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  Link,
} from '@mui/material';
import {CalendarIcon} from '../../assets/icons/calendar';
import {CashIcon} from '../../assets/icons/cash';
import {HomeIcon} from '../../assets/icons/home';
import FolderIcon from '@mui/icons-material/Folder';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReportIcon from '@mui/icons-material/Report';
import MessageIcon from '@mui/icons-material/Message';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {Scrollbar} from './scrollbar';
import {DashboardSidebarSection} from './dashboard-sidebar-section';
import {OrganizationPopover} from './organization-popover';
import {useLocation, useNavigate} from 'react-router-dom';
import {UserCircleIcon} from '../../assets/icons/user-circle';
import {FinSitefulHorizontalLogo} from '../Images/logos';

const getSections = () => [
  {
    title: 'Dashboard',
    items: [
      {
        title: 'At-Risk',
        path: '/dashboard/atRisk',
        icon: <ReportIcon fontSize="small" />,
      },
      {
        title: 'Financial',
        path: '/dashboard/financial',
        icon: <AccountBalanceIcon fontSize="small" />,
      },
      {
        title: 'Usage',
        path: '/dashboard/usage',
        icon: <PeopleIcon fontSize="small" />,
      },
      {
        title: 'Messenger',
        path: '/messenger',
        icon: <MessageIcon fontSize="small" />,
      },
      {
        title: 'Account',
        path: '/account',
        icon: <UserCircleIcon fontSize="small" />,
      },
    ],
  },
];

export const DashboardSidebar = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const {onClose, open, projects} = props;
  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const sections = useMemo(() => getSections(projects));
  const organizationsRef = useRef(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] =
    useState(false);

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(handlePathChange, [location]);

  const handleOpenOrganizationsPopover = () => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
          <div>
            <Box sx={{p: 3}}>
              <Link
                onClick={() => {
                  navigate('/dashboard/atRisk');
                }}>
                <FinSitefulHorizontalLogo
                  style={{
                    width: '128px',
                    height: '38px',
                  }}
                />
              </Link>
            </Box>
            {/* <Box sx={{px: 2}}>
              <Box
                onClick={handleOpenOrganizationsPopover}
                ref={organizationsRef}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  py: '11px',
                  borderRadius: 1,
                }}>
                <div>
                  <Typography color="inherit" variant="subtitle1">
                    Acme Inc
                  </Typography>
                  <Typography color="neutral.400" variant="body2">
                    {'Your tier'} : Premium
                  </Typography>
                </div>
                <ChevronDownIcon
                  sx={{
                    color: 'neutral.500',
                    width: 14,
                    height: 14,
                  }}
                />
              </Box>
            </Box> */}
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3,
            }}
          />
          <Box sx={{flexGrow: 1}}>
            {sections.map(section => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname || '/'}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                {...section}
              />
            ))}
          </Box>
        </Box>
      </Scrollbar>
      <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      />
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: theme => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{zIndex: theme => theme.zIndex.appBar + 100}}
      variant="temporary">
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
