import {auth} from './firebase';

import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
} from 'firebase/auth';

const createUser = async (email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const login = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (err) {
    throw err;
  }
};

const signout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    throw err;
  }
};

const changePassword = async (email, password, oldPassword) => {
  try {
    const user = auth.currentUser;
    await signInWithEmailAndPassword(auth, email, oldPassword);
    await updatePassword(user, password);
  } catch (err) {
    throw err;
  }
};

const changeEmail = async (email, oldEmail, password) => {
  try {
    const user = auth.currentUser;
    await signInWithEmailAndPassword(auth, oldEmail, password);
    await updateEmail(user, email);
    return user;
  } catch (err) {
    throw err;
  }
};

const sendResetPassword = async email => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    throw err;
  }
};

export {
  createUser,
  login,
  signout,
  changePassword,
  changeEmail,
  sendResetPassword,
};
