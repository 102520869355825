import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {DotsHorizontalIcon} from '../../assets/icons/dots-horizontal';
import {MailIcon} from '../../assets/icons/mail';
import {UserCircleIcon} from '../../assets/icons/user-circle';
import {Scrollbar} from '../../components/Layout/scrollbar';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {useAuth} from '../../hooks/useAuth';
import {useEffect, useState} from 'react';
import {useDashboard} from '../../hooks/useDashboard';
import {sendEmail, sendMessage} from '../../api/firestore';
import Spacer from '../../components/Common/Spacer';

export const AccountTeamSettings = () => {
  const {
    state: {universal},
  } = useAuth();
  const {
    state: {team},
    updateTeam,
  } = useDashboard();

  const [invites, setInvites] = useState('');

  const {members = [], name} = team || {};
  const fromEmail = universal.email || 'info@finsiteful.com';
  const organizationCode = team.organizationCode || null;

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h6">
            Invite members to {name || 'N/A'}
          </Typography>
          <Divider
            sx={{
              mt: 3,
              mb: 3,
            }}
          />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              m: -3,
            }}>
            <TextField
              value={invites}
              onChange={evt => {
                setInvites(evt.target.value);
              }}
              label="Email address"
              placeholder="Add multiple addresses separated by commas"
              size="small"
              sx={{
                m: 1.5,
                flexGrow: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{m: 1.5}}
              variant="contained"
              onClick={async () => {
                setInvites('');
                const emails = invites.split(',');
                const to = 'ccaswell@finsiteful.com';
                const subject = 'Invitation to Join FinSiteful';
                const text = `${fromEmail} has invited you to join FinSiteful. Click the link to continue signing up.`;
                const html = `${fromEmail} has invited you to join FinSiteful. Click the link to continue signing up.<br /><br/><a href="https://platform.finsiteful.com/signup?${organizationCode}">Sign up</a>`;

                const newMembers = emails.map(email => {
                  const cleaned = email.trim();
                  sendEmail({cleaned, subject, text, html});
                  return {
                    email: cleaned,
                    role: 'admin',
                    status: 'invited',
                    uid: null,
                  };
                });

                await updateTeam(organizationCode, {
                  members: [...team.members, ...newMembers],
                });
              }}>
              Send Invite
            </Button>
          </Box>
        </CardContent>
        <Scrollbar>
          <Table sx={{minWidth: 400}}>
            <TableHead>
              <TableRow>
                <TableCell>Member</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map(member => {
                const {email, role, status, firstName, lastName} = member;

                return (
                  <TableRow>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}>
                        <Avatar
                          sx={{
                            height: 40,
                            width: 40,
                            mr: 1,
                          }}>
                          <UserCircleIcon fontSize="small" />
                        </Avatar>
                        <div>
                          <Typography variant="subtitle2">
                            {firstName} {lastName}
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {email}
                          </Typography>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <SeverityPill>{role}</SeverityPill>
                    </TableCell>
                    <TableCell>{status || 'Invited'}</TableCell>
                    {/* <TableCell align="right">
                    <IconButton>
                      <DotsHorizontalIcon fontSize="small" />
                    </IconButton>
                  </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
      <Spacer style={{marginBottom: 200}} />
    </>
  );
};

const SeverityPillRoot = styled('span')(({theme, ownerState}) => {
  const backgroundColor = theme.palette[ownerState.color].main;
  const color = theme.palette[ownerState.color].contrastText;

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: 12,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 2,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  };
});

export const SeverityPill = props => {
  const {color = 'primary', children, ...other} = props;

  const ownerState = {color};

  return (
    <SeverityPillRoot ownerState={ownerState} {...other}>
      {children}
    </SeverityPillRoot>
  );
};

SeverityPill.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'info',
    'warning',
    'success',
  ]),
};
