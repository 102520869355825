const GlobalConstants = {
  COLORS: ['#0088FE', '#00C49F', '#FFBB28', 'purple', '#FF5722', 'orange'],

  BACKGROUND: '#F4F6FA',
  HIGHLIGHT: '#3492FF',
  RED: '#DE2B2B',
  GREEN: '#10874E',
  LIGHT_BLUE: '#AACEFF',
  GRAY: '#C4C4C4',
  HEADER: '#ADD8E6',

  PRIMARY_TEXT: '#41415a',

  CHECKING: '#4CD0D9',
  SAVINGS: '#39D78B',
  INVESTMENT: '#E9EB6F',
  CREDIT: '#FF6969',
  DEBT: '#D7F8D8',
  OTHER: '#ECECEC',
  TEXT: '#9d9db0',

  colors: [
    '#2D3748',
    '#FFB020',
    '#F44336',
    '#4CAF50',
    '#7BC67E',
    '#7783DB',
    '#9DA4DD',
    '#2196F3',
    '#64B6F7',
  ],

  cpccCode: 'cpcc23810674',
  bcccCode: 'bccc53028742',
  wvccCode: 'wvcc28403381',
  mccCode: 'mcc46901327',

  headers: [
    'Dashboard',
    'Financial',
    'At Risk',
    'Development',
    'Profile',
    'Goals',
    'Notifications',
  ],

  products: ['dashboard', 'pfm'],

  // LINKS
  privacyPolicy: 'https://finsiteful.com/privacy-policy-2/',
  termsOfService: 'https://finsiteful.com/terms-of-service/',
  caliOptOut: 'https://finsiteful.com/california-privacy-policy/',

  defaultNotifications: {
    newAtRisk: true,
    newMessage: true,
  },

  fakeResources: [
    {
      title: 'Testing',
      applicationLink: '',
      websiteLink: '',
      requirements: {
        degree: '',
        GPA: '',
        location: '',
        activities: '',
        grade: '',
        school: '',
        areaOfStudy: '',
        interests: '',
      },
      about: 'Some fake thing',
      deadLine: ['Opens: 11/1/2022', 'Deadline: 2/10/2023'],
      link: '',
      needBased: '',
      amount: '',
      meritBased: '',
      essay: '',
    },
  ],
};

export default GlobalConstants;
