import {db, functions} from './firebase';
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  query,
  getDocs,
  getDoc,
  where,
  limit,
  collectionGroup,
  deleteDoc,
  doc,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';

// TEAM
const getTeamSettings = async orgCode => {
  try {
    const docRef = doc(db, 'teams', orgCode);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }
    return {};
  } catch (err) {
    throw err;
  }
};

const updateTeamSettings = async (orgCode, update) => {
  try {
    const docRef = doc(db, 'teams', orgCode);
    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

// PROFILES
const getUniversalProfile = async uid => {
  try {
    const docRef = doc(db, 'users', uid);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }
    return null;
  } catch (err) {
    throw err;
  }
};

const updateUniversalProfile = async (uid, update) => {
  try {
    const docRef = doc(db, 'users', uid);
    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

const getFinancialProfile = async uid => {
  try {
    const docRef = doc(db, `users`, `${uid}/profile/profile`);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }

    return null;
  } catch (err) {
    throw err;
  }
};

const updateFinancialProfile = async (uid, update) => {
  try {
    const docRef = doc(db, `users`, `${uid}/profile/profile`);
    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

const updateDashboardProfile = async (uid, update) => {
  try {
    const docRef = doc(db, 'dashboardData', uid);
    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

// PLAID
const getAccounts = async uid => {
  try {
    const docRef = collection(db, `users`, `${uid}/plaidAccounts`);
    const accountDocs = await getDocs(docRef);

    if (accountDocs.size) {
      return accountDocs.docs.map(doc => doc.data());
    }

    return [];
  } catch (err) {
    throw err;
  }
};

const getTransactionsBetween = async (uid, start, end) => {
  try {
    const docRef = collection(db, `users`, `${uid}/plaidTransactions`);

    const transDocs = await getDocs(
      query(
        docRef,
        where('date', '>=', start),
        where('date', '<=', end),
        orderBy('date', 'desc'),
      ),
    );

    if (transDocs.size) {
      return transDocs.docs.map(doc => doc.data());
    }

    return [];
  } catch (err) {
    throw err;
  }
};

// DASHBOARD
const getDashboardData = async (filter = null) => {
  try {
    const deadline = new Date('2023-02-26');
    let group;
    if (filter) {
      group = query(
        collection(db, 'dashboardData'),
        where('organizationCode', '==', filter),
        where('dateCreated', '>', deadline),
      );
    } else {
      group = query(
        collection(db, 'dashboardData'),
        where('dateCreated', '>', deadline),
      );
    }
    const users = await getDocs(group);
    return users.docs.map(doc => doc.data());
  } catch (err) {
    throw err;
  }
};

const getAtRiskData = async (start, filter = null) => {
  try {
    let group;
    if (filter) {
      group = query(
        collectionGroup(db, 'atRisk'),
        where('date', '>=', start),
        where('organizationCode', '==', filter),
      );
    } else {
      group = query(collectionGroup(db, 'atRisk'), where('date', '>=', start));
    }
    const data = await getDocs(group);
    // TODO: check that exists first
    return data.docs.map(doc => doc.data());
  } catch (err) {
    throw err;
  }
};

// MESSAGES
const getMessages = async (filter, offset, page = 10) => {
  try {
    // TODO: paginate
    let messagesRef;
    if (filter) {
      messagesRef = query(
        collection(db, `messages`),
        where('organizationCode', '==', filter),
        orderBy('lastUpdated', 'desc'),
      );
    } else {
      messagesRef = query(
        collection(db, `messages`),
        orderBy('lastUpdated', 'desc'),
      );
    }

    const messageDocs = await getDocs(query(messagesRef));
    if (messageDocs.size) {
      return messageDocs.docs.map(doc => doc.data());
    }
    return [];
  } catch (err) {
    throw err;
  }
};

const getMessage = async uid => {
  try {
    const docRef = doc(db, `messages`, `${uid}/`);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }

    return null;
  } catch (err) {
    throw err;
  }
};

const updateMessageMetadata = async (uid, update) => {
  try {
    const docRef = doc(db, `messages`, `${uid}`);

    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

const getMessageChainListener = async (uid, callback) => {
  try {
    const docRef = query(
      collection(db, `messages`, `${uid}/atRisk`),
      orderBy('date', 'asc'),
    );

    const unsubscriber = onSnapshot(docRef, async snapshot => {
      if (snapshot) {
        const messages = snapshot.docs.map(doc => doc.data());
        const payload = {messages, uid};
        callback({type: 'getMessageChain', payload});
      }
    });
    return unsubscriber;
  } catch (err) {
    throw err;
  }
};

const sendMessage = async (uid, message) => {
  try {
    const docRef = doc(db, `messages`, `${uid}/atRisk/${message.uid}`);
    await setDoc(docRef, message, {merge: true});
  } catch (err) {
    throw err;
  }
};

const getNotificationSettings = async uid => {
  try {
    const docRef = doc(db, `users`, `${uid}/profile/notificationSettings`);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }

    return null;
  } catch (err) {
    throw err;
  }
};

const updateNotificationSettings = async (uid, update) => {
  try {
    const docRef = doc(db, `users`, `${uid}/profile/notificationSettings`);
    await setDoc(docRef, update, {merge: true});
  } catch (err) {
    throw err;
  }
};

// to, subject, text, html
const sendEmail = async email => {
  try {
    const send = httpsCallable(functions, 'email-sendEmail');
    const {data} = await send(email);
    return data;
  } catch (err) {
    throw err;
  }
};

// NOTES
const setNote = async (uid, note) => {
  try {
    const docRef = doc(db, `dashboardData`, `${uid}/notes/${note.uid}`);
    setDoc(docRef, note, {merge: true});
  } catch (err) {
    throw err;
  }
};

const deleteNote = async (uid, note) => {
  try {
    const docRef = doc(db, `dashboardData`, `${uid}/notes/${note.uid}`);
    deleteDoc(docRef);
  } catch (err) {
    throw err;
  }
};

const getNotes = async uid => {
  try {
    const docRef = collection(db, `dashboardData`, `${uid}/notes`);
    const noteDocs = await getDocs(query(docRef, orderBy('updated', 'desc')));

    if (noteDocs.size) {
      return noteDocs.docs.map(doc => doc.data());
    }

    return [];
  } catch (err) {
    throw err;
  }
};

export {
  getTeamSettings,
  updateTeamSettings,
  getUniversalProfile,
  updateUniversalProfile,
  getFinancialProfile,
  updateFinancialProfile,
  updateDashboardProfile,
  getAccounts,
  getTransactionsBetween,
  getDashboardData,
  getAtRiskData,
  getMessages,
  getMessage,
  updateMessageMetadata,
  getMessageChainListener,
  sendMessage,
  getNotificationSettings,
  updateNotificationSettings,
  sendEmail,
  setNote,
  deleteNote,
  getNotes,
};
